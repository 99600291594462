import { AreaChartPlotType } from 'app/App.components/Chart/helpers/Chart.types'

export const MLI_FEE_CHART_DATA = [
  { time: 0.0001, value: 29.9999475 },
  { time: 1, value: 29.4775 },
  { time: 2, value: 28.96 },
  { time: 3, value: 28.4475 },
  { time: 4, value: 27.94 },
  { time: 5, value: 27.4375 },
  { time: 6, value: 26.94 },
  { time: 7, value: 26.4475 },
  { time: 8, value: 25.96 },
  { time: 9, value: 25.4775 },
  { time: 10, value: 25 },
  { time: 11, value: 24.5275 },
  { time: 12, value: 24.06 },
  { time: 13, value: 23.5975 },
  { time: 14, value: 23.14 },
  { time: 15, value: 22.6875 },
  { time: 16, value: 22.24 },
  { time: 17, value: 21.7975 },
  { time: 18, value: 21.36 },
  { time: 19, value: 20.9275 },
  { time: 20, value: 20.5 },
  { time: 21, value: 20.0775 },
  { time: 22, value: 19.66 },
  { time: 23, value: 19.2475 },
  { time: 24, value: 18.84 },
  { time: 25, value: 18.4375 },
  { time: 26, value: 18.04 },
  { time: 27, value: 17.6475 },
  { time: 28, value: 17.26 },
  { time: 29, value: 16.8775 },
  { time: 30, value: 16.5 },
  { time: 31, value: 16.1275 },
  { time: 32, value: 15.76 },
  { time: 33, value: 15.3975 },
  { time: 34, value: 15.04 },
  { time: 35, value: 14.6875 },
  { time: 36, value: 14.34 },
  { time: 37, value: 13.9975 },
  { time: 38, value: 13.66 },
  { time: 39, value: 13.3275 },
  { time: 40, value: 13 },
  { time: 41, value: 12.6775 },
  { time: 42, value: 12.36 },
  { time: 43, value: 12.0475 },
  { time: 44, value: 11.74 },
  { time: 45, value: 11.4375 },
  { time: 46, value: 11.14 },
  { time: 47, value: 10.8475 },
  { time: 48, value: 10.56 },
  { time: 49, value: 10.2775 },
  { time: 50, value: 10 },
  { time: 51, value: 9.7275 },
  { time: 52, value: 9.46 },
  { time: 53, value: 9.1975 },
  { time: 54, value: 8.94 },
  { time: 55, value: 8.6875 },
  { time: 56, value: 8.44 },
  { time: 57, value: 8.1975 },
  { time: 58, value: 7.96 },
  { time: 59, value: 7.7275 },
  { time: 60, value: 7.5 },
  { time: 61, value: 7.2775 },
  { time: 62, value: 7.06 },
  { time: 63, value: 6.8475 },
  { time: 64, value: 6.64 },
  { time: 65, value: 6.4375 },
  { time: 66, value: 6.24 },
  { time: 67, value: 6.0475 },
  { time: 68, value: 5.86 },
  { time: 69, value: 5.6775 },
  { time: 70, value: 5.5 },
  { time: 71, value: 5.3275 },
  { time: 72, value: 5.16 },
  { time: 73, value: 4.9975 },
  { time: 74, value: 4.84 },
  { time: 75, value: 4.6875 },
  { time: 76, value: 4.54 },
  { time: 77, value: 4.3975 },
  { time: 78, value: 4.26 },
  { time: 79, value: 4.1275 },
  { time: 80, value: 4 },
  { time: 81, value: 3.8775 },
  { time: 82, value: 3.76 },
  { time: 83, value: 3.6475 },
  { time: 84, value: 3.54 },
  { time: 85, value: 3.4375 },
  { time: 86, value: 3.34 },
  { time: 87, value: 3.2475 },
  { time: 88, value: 3.16 },
  { time: 89, value: 3.0775 },
  { time: 90, value: 3 },
  { time: 91, value: 2.9275 },
  { time: 92, value: 2.86 },
  { time: 93, value: 2.7975 },
  { time: 94, value: 2.74 },
  { time: 95, value: 2.6875 },
  { time: 96, value: 2.64 },
  { time: 97, value: 2.5975 },
  { time: 98, value: 2.56 },
  { time: 99, value: 2.5275 },
  { time: 100, value: 2.5275 },
  { time: 100.001 },
  { time: 100.002 },
] as Array<AreaChartPlotType>
