import { PageHeaderContent } from './PageHeader.constants'

export const PAGE_HEADER_DATA = new Map<string, PageHeaderContent>([
  [
    'doorman',
    {
      title: 'Staking',
      subText: 'Lock your MVN and earn rewards through loan income.',
      foregroundImageSrc: '/images/staking-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'lending',
    {
      title: 'Earn and Borrow Markets',
      subText: 'View all available markets and see details to supply and borrow assets.',
      foregroundImageSrc: '/images/lending-header.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'loansDashboard',
    {
      title: 'Earn/Borrow Dashboard',
      subText: 'View all available markets and see details to supply and borrow assets.',
      foregroundImageSrc: '/images/lending-header.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'loansEarn',
    {
      title: 'Earn Yield on Your Assets',
      subText: 'Earn yield by depositing your tokens',
      foregroundImageSrc: '/images/lending-header.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'loansBorrow',
    {
      title: 'Borrow',
      subText: 'Borrow stablecoins and bitcoin using your assets as collateral',
      foregroundImageSrc: '/images/lending-header.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'dashboard',
    {
      title: 'Dashboard',
      subText: 'Take control of your financial future.',
      foregroundImageSrc: '/images/dash-main-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'personal',
    {
      title: 'Your Finances',
      subText: 'Don’t follow the herd, be a Maven.',
      foregroundImageSrc: '/images/dash-main-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'vesting',
    {
      title: 'Vesting Management',
      subText: 'Overview of your vested finances.',
      foregroundImageSrc: '/images/dash-main-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'satellites',
    {
      title: 'Satellites',
      subText: 'Delegate your voting power to Satellites and earn rewards.',
      foregroundImageSrc: '/images/satellites-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'my satellite profile',
    {
      title: 'My Satellite Profile',
      subText: 'Delegate your voting power to Satellites and earn rewards.',
      foregroundImageSrc: '/images/satellites-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'satellite details',
    {
      title: 'Satellites',
      subText: 'Delegate your voting power to Satellites and earn rewards',
      foregroundImageSrc: '/images/satellites-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'satellite registration',
    {
      title: 'Satellite Registration',
      subText: 'Become a satellite and take part in the future of finance',
      foregroundImageSrc: '/images/satellites-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'oracle nodes',
    {
      title: 'Oracle Nodes',
      subText: '',
      foregroundImageSrc: '/images/satellites-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'oracle feeds',
    {
      title: 'Oracle Feeds',
      subText: '',
      foregroundImageSrc: '/images/satellites-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'governance',
    {
      title: 'Governance',
      subText: 'Use your tokens to vote on proposals, or delegate your votes to a satellite.',
      foregroundImageSrc: '/images/gov-main-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'financial requests',
    {
      title: 'Financial Requests',
      subText: 'Vote on the Maven Council financial actions',
      foregroundImageSrc: '/images/gov-main-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'council',
    {
      title: 'Maven Council',
      subText: 'Responsible for performing operations to develop the Maven Finance ecosystem',
      foregroundImageSrc: '/images/gov-main-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'satellite-governance',
    {
      title: 'Satellite Governance',
      subText: 'Take part in governing satellite behavior and ensuring all are good actors.',
      foregroundImageSrc: '/images/satellite-governance-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'proposal submission',
    {
      title: 'Governance Proposal Submission',
      subText: 'Use your tokens to vote on proposals, or delegate your votes to a satellite.',
      foregroundImageSrc: '/images/gov-main-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'break glass',
    {
      title: 'Contract Status',
      subText: 'Contract status and emergency shutdown protocol',
      foregroundImageSrc: '/images/break-glass-header.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'break glass council',
    {
      title: 'Break Glass Council',
      subText: 'Break Glass Council page',
      foregroundImageSrc: '/images/break-glass-header.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'break glass actions',
    {
      title: 'Break Glass Actions',
      subText: 'Break Glass Actions page',
      foregroundImageSrc: '/images/break-glass-header.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'emergency governance',
    {
      title: 'Emergency Governance',
      subText: 'Fast proposal and execution for emergency situations',
      foregroundImageSrc: '/images/break-glass-header.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'council member',
    {
      title: 'Council Member',
      subText: 'Council Member dashboard',
      foregroundImageSrc: '',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'treasury',
    {
      title: 'Treasury',
      subText: 'The treasury holds assets for the development of the Maven Finance ecosystem.',
      foregroundImageSrc: '/images/treasury-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'vaults',
    {
      title: 'Vaults',
      subText: 'Bid on auctions of collateralized debt.',
      foregroundImageSrc: '/images/vaults-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'loans',
    {
      title: 'Loans',
      subText: 'Take a USDM loan from the vault using your assets as collateral.',
      foregroundImageSrc: '/images/vaults-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'farms',
    {
      title: 'Yield Farms',
      subText: 'Provide LP and MVN Tokens to the liquidity pools and gain rewards.',
      foregroundImageSrc: '/images/farms-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'admin',
    {
      title: 'Admin',
      subText: 'For developers only.',
      foregroundImageSrc: '/images/admin-header-fg.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'data-feeds',
    {
      title: 'Data Feeds',
      subText: 'Empower your Dapps by using our decentralized data feeds',
      foregroundImageSrc: '/images/data-feeds-header.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
  [
    'oracles-users',
    {
      title: 'Oracles Users',
      subText: 'Decentralized oracles powered by Maven Finance',
      foregroundImageSrc: '/images/data-feeds-header.svg',
      backgroundImageSrc: '/images/dapp-header-bg.svg',
    },
  ],
])
