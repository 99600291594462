export const CONTRACT_ERROR_CODES: Map<number, { message: string; description: string }> = new Map([
  [1, { message: 'error_INCORRECT_TEZ_FEE', description: 'INCORRECT TEZ FEE' }],
  [2, { message: 'error_LAMBDA_NOT_FOUND', description: 'LAMBDA NOT FOUND' }],
  [3, { message: 'error_UNABLE_TO_UNPACK_LAMBDA', description: 'UNABLE TO UNPACK LAMBDA' }],
  [4, { message: 'error_UNABLE_TO_UNPACK_ACTION_PARAMETER', description: 'UNABLE TO UNPACK ACTION PARAMETER' }],
  [5, { message: 'error_CALCULATION_ERROR', description: 'CALCULATION ERROR' }],
  [6, { message: 'error_CONFIG_VALUE_ERROR', description: 'CONFIG VALUE ERROR' }],
  [7, { message: 'error_CONFIG_VALUE_TOO_HIGH', description: 'CONFIG VALUE TOO HIGH' }],
  [8, { message: 'error_CONFIG_VALUE_TOO_LOW', description: 'CONFIG VALUE TOO LOW' }],
  [9, { message: 'error_INDEX_OUT_OF_BOUNDS', description: 'INDEX OUT OF BOUNDS' }],
  [10, { message: 'error_INVALID_BLOCKS_PER_MINUTE', description: 'INVALID BLOCKS PER MINUTE' }],
  [11, { message: 'error_WRONG_INPUT_PROVIDED', description: 'WRONG INPUT PROVIDED' }],
  [12, { message: 'error_WRONG_TOKEN_TYPE_PROVIDED', description: 'WRONG TOKEN TYPE PROVIDED' }],
  [13, { message: 'error_TOKEN_NOT_WHITELISTED', description: 'TOKEN NOT WHITELISTED' }],
  [14, { message: 'error_ONLY_ADMINISTRATOR_ALLOWED', description: 'ONLY ADMINISTRATOR ALLOWED' }],
  [15, { message: 'error_ONLY_SELF_ALLOWED', description: 'ONLY SELF ALLOWED' }],
  [
    16,
    {
      message: 'error_ONLY_ADMINISTRATOR_OR_GOVERNANCE_ALLOWED',
      description: 'ONLY ADMINISTRATOR OR GOVERNANCE ALLOWED',
    },
  ],
  [
    17,
    {
      message: 'error_ONLY_ADMINISTRATOR_OR_GOVERNANCE_FINANCIAL_ALLOWED',
      description: 'ONLY ADMINISTRATOR OR GOVERNANCE FINANCIAL ALLOWED',
    },
  ],
  [18, { message: 'error_ONLY_COUNCIL_MEMBERS_ALLOWED', description: 'ONLY COUNCIL MEMBERS ALLOWED' }],
  [
    19,
    {
      message: 'error_ONLY_ADMINISTRATOR_OR_WHITELISTED_ADDRESSES_ALLOWED',
      description: 'ONLY ADMINISTRATOR OR WHITELISTED ADDRESSES ALLOWED',
    },
  ],
  [20, { message: 'error_ONLY_WHITELISTED_ADDRESSES_ALLOWED', description: 'ONLY WHITELISTED ADDRESSES ALLOWED' }],
  [21, { message: 'error_ONLY_PROPOSER_ALLOWED', description: 'ONLY PROPOSER ALLOWED' }],
  [
    22,
    {
      message: 'error_ONLY_BREAK_GLASS_CONTRACT_OR_DEVELOPERS_OR_PROXY_CONTRACT_ALLOWED',
      description: 'ONLY BREAK GLASS CONTRACT OR DEVELOPERS OR PROXY CONTRACT ALLOWED',
    },
  ],
  [23, { message: 'error_SPECIFIED_ENTRYPOINT_NOT_FOUND', description: 'SPECIFIED ENTRYPOINT NOT FOUND' }],
  [24, { message: 'error_SET_ADMIN_ENTRYPOINT_NOT_FOUND', description: 'SET ADMIN ENTRYPOINT NOT FOUND' }],
  [25, { message: 'error_SET_GOVERNANCE_ENTRYPOINT_NOT_FOUND', description: 'SET GOVERNANCE ENTRYPOINT NOT FOUND' }],
  [26, { message: 'error_SET_LAMBDA_ENTRYPOINT_NOT_FOUND', description: 'SET LAMBDA ENTRYPOINT NOT FOUND' }],
  [
    27,
    {
      message: 'error_SET_PRODUCT_LAMBDA_ENTRYPOINT_NOT_FOUND',
      description: 'SET PRODUCT LAMBDA ENTRYPOINT NOT FOUND',
    },
  ],
  [28, { message: 'error_BREAK_GLASS_ENTRYPOINT_NOT_FOUND', description: 'BREAK GLASS ENTRYPOINT NOT FOUND' }],
  [29, { message: 'error_PAUSE_ALL_ENTRYPOINT_NOT_FOUND', description: 'PAUSE ALL ENTRYPOINT NOT FOUND' }],
  [30, { message: 'error_UNPAUSE_ALL_ENTRYPOINT_NOT_FOUND', description: 'UNPAUSE ALL ENTRYPOINT NOT FOUND' }],
  [31, { message: 'error_UPDATE_METADATA_ENTRYPOINT_NOT_FOUND', description: 'UPDATE METADATA ENTRYPOINT NOT FOUND' }],
  [
    32,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT NOT FOUND',
    },
  ],
  [
    33,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT NOT FOUND',
    },
  ],
  [34, { message: 'error_SET_NAME_ENTRYPOINT_NOT_FOUND', description: 'SET NAME ENTRYPOINT NOT FOUND' }],
  [
    35,
    {
      message: 'error_UPDATE_WHITELIST_TOKEN_CONTRACTS_ENTRYPOINT_NOT_FOUND',
      description: 'UPDATE WHITELIST TOKEN CONTRACTS ENTRYPOINT NOT FOUND',
    },
  ],
  [
    36,
    {
      message: 'error_UPDATE_BLOCKS_PER_MIN_ENTRYPOINT_NOT_FOUND',
      description: 'UPDATE BLOCKS PER MIN ENTRYPOINT NOT FOUND',
    },
  ],
  [
    37,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT NOT FOUND',
    },
  ],
  [
    38,
    {
      message: 'error_TRANSFER_ENTRYPOINT_IN_FA12_CONTRACT_NOT_FOUND',
      description: 'TRANSFER ENTRYPOINT IN FA12 CONTRACT NOT FOUND',
    },
  ],
  [
    39,
    {
      message: 'error_TRANSFER_ENTRYPOINT_IN_FA2_CONTRACT_NOT_FOUND',
      description: 'TRANSFER ENTRYPOINT IN FA2 CONTRACT NOT FOUND',
    },
  ],
  [40, { message: 'error_COUNCIL_SIZE_EXCEEDED', description: 'COUNCIL SIZE EXCEEDED' }],
  [41, { message: 'error_COUNCIL_MEMBER_ALREADY_EXISTS', description: 'COUNCIL MEMBER ALREADY EXISTS' }],
  [42, { message: 'error_COUNCIL_MEMBER_NOT_FOUND', description: 'COUNCIL MEMBER NOT FOUND' }],
  [43, { message: 'error_COUNCIL_THRESHOLD_ERROR', description: 'COUNCIL THRESHOLD ERROR' }],
  [44, { message: 'error_COUNCIL_ACTION_NOT_FOUND', description: 'COUNCIL ACTION NOT FOUND' }],
  [45, { message: 'error_COUNCIL_ACTION_EXECUTED', description: 'COUNCIL ACTION EXECUTED' }],
  [46, { message: 'error_COUNCIL_ACTION_FLUSHED', description: 'COUNCIL ACTION FLUSHED' }],
  [47, { message: 'error_COUNCIL_ACTION_EXPIRED', description: 'COUNCIL ACTION EXPIRED' }],
  [48, { message: 'error_COUNCIL_ACTION_PARAMETER_NOT_FOUND', description: 'COUNCIL ACTION PARAMETER NOT FOUND' }],
  [
    49,
    {
      message: 'error_COUNCIL_ACTION_ALREADY_SIGNED_BY_SENDER',
      description: 'COUNCIL ACTION ALREADY SIGNED BY SENDER',
    },
  ],
  [50, { message: 'error_MVK_TOKEN_CONTRACT_NOT_FOUND', description: 'MVK TOKEN CONTRACT NOT FOUND' }],
  [51, { message: 'error_ONLY_MVK_TOKEN_CONTRACT_ALLOWED', description: 'ONLY MVK TOKEN CONTRACT ALLOWED' }],
  [52, { message: 'error_MAXIMUM_SUPPLY_EXCEEDED', description: 'MAXIMUM SUPPLY EXCEEDED' }],
  [53, { message: 'error_INFLATION_RATE_TOO_HIGH', description: 'INFLATION RATE TOO HIGH' }],
  [54, { message: 'error_CANNOT_TRIGGER_INFLATION_NOW', description: 'CANNOT TRIGGER INFLATION NOW' }],
  [
    55,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    56,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    57,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    58,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    59,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    60,
    {
      message: 'error_ASSERT_METADATA_ENTRYPOINT_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'ASSERT METADATA ENTRYPOINT IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    61,
    {
      message: 'error_TRANSFER_ENTRYPOINT_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'TRANSFER ENTRYPOINT IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    62,
    {
      message: 'error_BALANCE_OF_ENTRYPOINT_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'BALANCE OF ENTRYPOINT IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    63,
    {
      message: 'error_UPDATE_OPERATORS_ENTRYPOINT_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'UPDATE OPERATORS ENTRYPOINT IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    64,
    {
      message: 'error_MINT_ENTRYPOINT_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'MINT ENTRYPOINT IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    65,
    {
      message: 'error_UPDATE_INFLATION_RATE_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'UPDATE INFLATION RATE IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    66,
    {
      message: 'error_TRIGGER_INFLATION_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'TRIGGER INFLATION IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    67,
    {
      message: 'error_GET_ADMIN_VIEW_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    68,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    69,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    70,
    {
      message: 'error_GET_INFLATION_RATE_VIEW_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET INFLATION RATE VIEW IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    71,
    {
      message: 'error_GET_NEXT_INFLATION_TIMESTAMP_VIEW_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET NEXT INFLATION TIMESTAMP VIEW IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    72,
    {
      message: 'error_GET_OPERATOR_OPT_VIEW_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET OPERATOR OPT VIEW IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    73,
    {
      message: 'error_GET_BALANCE_VIEW_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET BALANCE VIEW IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    74,
    {
      message: 'error_GET_TOTAL_SUPPLY_VIEW_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET TOTAL SUPPLY VIEW IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    75,
    {
      message: 'error_GET_MAXIMUM_SUPPLY_VIEW_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET MAXIMUM SUPPLY VIEW IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    76,
    {
      message: 'error_GET_TOTAL_AND_MAXIMUM_SUPPLY_VIEW_IN_MVK_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET TOTAL AND MAXIMUM SUPPLY VIEW IN MVK TOKEN CONTRACT NOT FOUND',
    },
  ],
  [77, { message: 'error_BREAK_GLASS_CONTRACT_NOT_FOUND', description: 'BREAK GLASS CONTRACT NOT FOUND' }],
  [78, { message: 'error_ONLY_BREAK_GLASS_CONTRACT_ALLOWED', description: 'ONLY BREAK GLASS CONTRACT ALLOWED' }],
  [79, { message: 'error_GLASS_NOT_BROKEN', description: 'GLASS NOT BROKEN' }],
  [
    80,
    {
      message: 'error_BREAK_GLASS_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'BREAK GLASS ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    81,
    {
      message: 'error_PROPAGATE_BREAK_GLASS_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'PROPAGATE BREAK GLASS ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    82,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    83,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    84,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    85,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    86,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    87,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    88,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    89,
    {
      message: 'error_UPDATE_COUNCIL_MEMBER_INFO_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'UPDATE COUNCIL MEMBER INFO ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    90,
    {
      message: 'error_ADD_COUNCIL_MEMBER_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'ADD COUNCIL MEMBER ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    91,
    {
      message: 'error_REMOVE_COUNCIL_MEMBER_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'REMOVE COUNCIL MEMBER ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    92,
    {
      message: 'error_CHANGE_COUNCIL_MEMBER_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'CHANGE COUNCIL MEMBER ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    93,
    {
      message: 'error_FLUSH_ACTION_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'FLUSH ACTION ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    94,
    {
      message: 'error_SIGN_ACTION_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'SIGN ACTION ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    95,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    96,
    {
      message: 'error_GET_ADMIN_VIEW_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    97,
    {
      message: 'error_GET_GLASS_BROKEN_VIEW_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'GET GLASS BROKEN VIEW IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    98,
    {
      message: 'error_GET_CONFIG_VIEW_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    99,
    {
      message: 'error_GET_COUNCIL_MEMBERS_VIEW_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'GET COUNCIL MEMBERS VIEW IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    100,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    101,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    102,
    {
      message: 'error_GET_ACTION_OPT_VIEW_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'GET ACTION OPT VIEW IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    103,
    {
      message: 'error_GET_ACTION_COUNTER_VIEW_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'GET ACTION COUNTER VIEW IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    104,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [
    105,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_BREAK_GLASS_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN BREAK GLASS CONTRACT NOT FOUND',
    },
  ],
  [106, { message: 'error_COUNCIL_CONTRACT_NOT_FOUND', description: 'COUNCIL CONTRACT NOT FOUND' }],
  [107, { message: 'error_ONLY_COUNCIL_CONTRACT_ALLOWED', description: 'ONLY COUNCIL CONTRACT ALLOWED' }],
  [
    108,
    {
      message: 'error_ONLY_COUNCIL_CONTRACT_OR_ADMINISTRATOR_ALLOWED',
      description: 'ONLY COUNCIL CONTRACT OR ADMINISTRATOR ALLOWED',
    },
  ],
  [
    109,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_COUNCIL_CONTRACT',
      description: 'SET ADMIN ENTRYPOINT IN COUNCIL CONTRACT',
    },
  ],
  [
    110,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    111,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    112,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    113,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    114,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    115,
    {
      message: 'error_UPDATE_COUNCIL_MEMBER_INFO_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'UPDATE COUNCIL MEMBER INFO ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    116,
    {
      message: 'error_COUNCIL_ACTION_ADD_MEMBER_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION ADD MEMBER ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    117,
    {
      message: 'error_COUNCIL_ACTION_REMOVE_MEMBER_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION REMOVE MEMBER ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    118,
    {
      message: 'error_COUNCIL_ACTION_CHANGE_MEMBER_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION CHANGE MEMBER ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    119,
    {
      message: 'error_COUNCIL_ACTION_SET_BAKER_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION SET BAKER ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    120,
    {
      message: 'error_COUNCIL_ACTION_UPDATE_BLOCKS_PER_MIN_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION UPDATE BLOCKS PER MIN ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    121,
    {
      message: 'error_COUNCIL_ACTION_ADD_VESTEE_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION ADD VESTEE ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    122,
    {
      message: 'error_COUNCIL_ACTION_REMOVE_VESTEE_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION REMOVE VESTEE ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    123,
    {
      message: 'error_COUNCIL_ACTION_UPDATE_VESTEE_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION UPDATE VESTEE ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    124,
    {
      message: 'error_COUNCIL_ACTION_TOGGLE_VESTEE_LOCK_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION TOGGLE VESTEE LOCK ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    125,
    {
      message: 'error_COUNCIL_ACTION_TRANSFER_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION TRANSFER ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    126,
    {
      message: 'error_COUNCIL_ACTION_REQUEST_TOKENS_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION REQUEST TOKENS ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    127,
    {
      message: 'error_COUNCIL_ACTION_REQUEST_MINT_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION REQUEST MINT ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    128,
    {
      message: 'error_COUNCIL_ACTION_SET_CONTRACT_BAKER_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION SET CONTRACT BAKER ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    129,
    {
      message: 'error_COUNCIL_ACTION_DROP_FINANCIAL_REQ_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'COUNCIL ACTION DROP FINANCIAL REQ ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    130,
    {
      message: 'error_FLUSH_ACTION_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'FLUSH ACTION ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    131,
    {
      message: 'error_SIGN_ACTION_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'SIGN ACTION ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    132,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    133,
    {
      message: 'error_GET_ADMIN_VIEW_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    134,
    {
      message: 'error_GET_CONFIG_VIEW_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    135,
    {
      message: 'error_GET_COUNCIL_MEMBERS_VIEW_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'GET COUNCIL MEMBERS VIEW IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    136,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    137,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    138,
    {
      message: 'error_GET_COUNCIL_ACTION_VIEW_OPT_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'GET COUNCIL ACTION VIEW OPT IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    139,
    {
      message: 'error_GET_ACTION_COUNTER_VIEW_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'GET ACTION COUNTER VIEW IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    140,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [
    141,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_COUNCIL_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN COUNCIL CONTRACT NOT FOUND',
    },
  ],
  [142, { message: 'error_DELEGATION_CONTRACT_NOT_FOUND', description: 'DELEGATION CONTRACT NOT FOUND' }],
  [
    143,
    {
      message: 'error_SATELLITE_TREASURY_CONTRACT_NOT_FOUND',
      description: 'SATELLITE TREASURY CONTRACT NOT FOUND',
    },
  ],
  [144, { message: 'error_ONLY_DELEGATION_CONTRACT_ALLOWED', description: 'ONLY DELEGATION CONTRACT ALLOWED' }],
  [
    145,
    {
      message: 'error_ONLY_SELF_OR_SPECIFIED_ADDRESS_ALLOWED',
      description: 'ONLY SELF OR SPECIFIED ADDRESS ALLOWED',
    },
  ],
  [146, { message: 'error_ONLY_SATELLITE_ALLOWED', description: 'ONLY SATELLITE ALLOWED' }],
  [147, { message: 'error_SATELLITE_NOT_ALLOWED', description: 'SATELLITE NOT ALLOWED' }],
  [148, { message: 'error_SATELLITE_NOT_FOUND', description: 'SATELLITE NOT FOUND' }],
  [149, { message: 'error_SATELLITE_ALREADY_EXISTS', description: 'SATELLITE ALREADY EXISTS' }],
  [150, { message: 'error_SATELLITE_SUSPENDED', description: 'SATELLITE SUSPENDED' }],
  [151, { message: 'error_SATELLITE_BANNED', description: 'SATELLITE BANNED' }],
  [152, { message: 'error_DELEGATE_NOT_ALLOWED', description: 'DELEGATE NOT ALLOWED' }],
  [153, { message: 'error_DELEGATE_NOT_FOUND', description: 'DELEGATE NOT FOUND' }],
  [154, { message: 'error_DELEGATE_ALREADY_EXISTS', description: 'DELEGATE ALREADY EXISTS' }],
  [155, { message: 'error_ALREADY_DELEGATED_SATELLITE', description: 'ALREADY DELEGATED SATELLITE' }],
  [156, { message: 'error_SATELLITE_REWARDS_NOT_FOUND', description: 'SATELLITE REWARDS NOT FOUND' }],
  [
    157,
    {
      message: 'error_REFERENCE_SATELLITE_REWARDS_RECORD_NOT_FOUND',
      description: 'REFERENCE SATELLITE REWARDS RECORD NOT FOUND',
    },
  ],
  [158, { message: 'error_MAXIMUM_AMOUNT_OF_SATELLITES_REACHED', description: 'MAXIMUM AMOUNT OF SATELLITES REACHED' }],
  [
    159,
    {
      message: 'error_STAKE_EXCEEDS_SATELLITE_DELEGATED_AMOUNT',
      description: 'STAKE EXCEEDS SATELLITE DELEGATED AMOUNT',
    },
  ],
  [160, { message: 'error_SATELLITE_FEE_EXCEEDS_TOTAL_REWARD', description: 'SATELLITE FEE EXCEEDS TOTAL REWARD' }],
  [
    161,
    {
      message: 'error_DELEGATE_TO_SATELLITE_ENTRYPOINT_IN_DELEGATION_CONTRACT_PAUSED',
      description: 'DELEGATE TO SATELLITE ENTRYPOINT IN DELEGATION CONTRACT PAUSED',
    },
  ],
  [
    162,
    {
      message: 'error_UNDELEGATE_FROM_SATELLITE_ENTRYPOINT_IN_DELEGATION_CONTRACT_PAUSED',
      description: 'UNDELEGATE FROM SATELLITE ENTRYPOINT IN DELEGATION CONTRACT PAUSED',
    },
  ],
  [
    163,
    {
      message: 'error_REGISTER_AS_SATELLITE_ENTRYPOINT_IN_DELEGATION_CONTRACT_PAUSED',
      description: 'REGISTER AS SATELLITE ENTRYPOINT IN DELEGATION CONTRACT PAUSED',
    },
  ],
  [
    164,
    {
      message: 'error_UNREGISTER_AS_SATELLITE_ENTRYPOINT_IN_DELEGATION_CONTRACT_PAUSED',
      description: 'UNREGISTER AS SATELLITE ENTRYPOINT IN DELEGATION CONTRACT PAUSED',
    },
  ],
  [
    165,
    {
      message: 'error_UPDATE_SATELLITE_RECORD_ENTRYPOINT_IN_DELEGATION_CONTRACT_PAUSED',
      description: 'UPDATE SATELLITE RECORD ENTRYPOINT IN DELEGATION CONTRACT PAUSED',
    },
  ],
  [
    166,
    {
      message: 'error_DISTRIBUTE_REWARD_ENTRYPOINT_IN_DELEGATION_CONTRACT_PAUSED',
      description: 'DISTRIBUTE REWARD ENTRYPOINT IN DELEGATION CONTRACT PAUSED',
    },
  ],
  [
    167,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    168,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    169,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    170,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    171,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    172,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    173,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    174,
    {
      message: 'error_PAUSE_ALL_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'PAUSE ALL ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    175,
    {
      message: 'error_UNPAUSE_ALL_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'UNPAUSE ALL ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    176,
    {
      message: 'error_TOGGLE_PAUSE_ENTRYPOINT_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'TOGGLE PAUSE ENTRYPOINT ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    177,
    {
      message: 'error_DELEGATE_TO_SATELLITE_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'DELEGATE TO SATELLITE ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    178,
    {
      message: 'error_UNDELEGATE_FROM_SATELLITE_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'UNDELEGATE FROM SATELLITE ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    179,
    {
      message: 'error_REGISTER_AS_SATELLITE_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'REGISTER AS SATELLITE ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    180,
    {
      message: 'error_UNREGISTER_AS_SATELLITE_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'UNREGISTER AS SATELLITE ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    181,
    {
      message: 'error_UPDATE_SATELLITE_RECORD_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'UPDATE SATELLITE RECORD ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    182,
    {
      message: 'error_DISTRIBUTE_REWARD_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'DISTRIBUTE REWARD ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    183,
    {
      message: 'error_ON_STAKE_CHANGE_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'ON STAKE CHANGE ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    184,
    {
      message: 'error_UPDATE_SATELLITE_STATUS_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'UPDATE SATELLITE STATUS ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    185,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    186,
    {
      message: 'error_GET_ADMIN_VIEW_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    187,
    {
      message: 'error_GET_CONFIG_VIEW_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    188,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    189,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    190,
    {
      message: 'error_GET_BREAK_GLASS_CONFIG_VIEW_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'GET BREAK GLASS CONFIG VIEW IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    191,
    {
      message: 'error_GET_DELEGATE_OPT_VIEW_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'GET DELEGATE OPT VIEW IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    192,
    {
      message: 'error_GET_SATELLITE_OPT_VIEW_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'GET SATELLITE OPT VIEW IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    193,
    {
      message: 'error_GET_SATELLITE_REWARDS_OPT_VIEW_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'GET SATELLITE REWARDS OPT VIEW IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    194,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [
    195,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_DELEGATION_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN DELEGATION CONTRACT NOT FOUND',
    },
  ],
  [196, { message: 'error_DOORMAN_CONTRACT_NOT_FOUND', description: 'DOORMAN CONTRACT NOT FOUND' }],
  [197, { message: 'error_ONLY_DOORMAN_CONTRACT_ALLOWED', description: 'ONLY DOORMAN CONTRACT ALLOWED' }],
  [198, { message: 'error_FARM_TREASURY_CONTRACT_NOT_FOUND', description: 'FARM TREASURY CONTRACT NOT FOUND' }],
  [199, { message: 'error_SMVK_ACCESS_AMOUNT_NOT_REACHED', description: 'SMVK ACCESS AMOUNT NOT REACHED' }],
  [200, { message: 'error_MVK_ACCESS_AMOUNT_NOT_REACHED', description: 'MVK ACCESS AMOUNT NOT REACHED' }],
  [201, { message: 'error_USER_STAKE_RECORD_NOT_FOUND', description: 'USER STAKE RECORD NOT FOUND' }],
  [202, { message: 'error_VAULT_STAKE_RECORD_NOT_FOUND', description: 'VAULT STAKE RECORD NOT FOUND' }],
  [203, { message: 'error_NOT_ENOUGH_SMVK_BALANCE', description: 'NOT ENOUGH SMVK BALANCE' }],
  [204, { message: 'error_UNSTAKE_AMOUNT_ERROR', description: 'UNSTAKE AMOUNT ERROR' }],
  [
    205,
    {
      message: 'error_CANNOT_TRANSFER_MVK_TOKEN_USING_MISTAKEN_TRANSFER',
      description: 'CANNOT TRANSFER MVK TOKEN USING MISTAKEN TRANSFER',
    },
  ],
  [206, { message: 'w', description: 'STAKE ENTRYPOINT IN DOORMAN CONTRACT PAUSED' }],
  [
    207,
    {
      message: 'error_UNSTAKE_ENTRYPOINT_IN_DOORMAN_CONTRACT_PAUSED',
      description: 'UNSTAKE ENTRYPOINT IN DOORMAN CONTRACT PAUSED',
    },
  ],
  [
    208,
    {
      message: 'error_COMPOUND_ENTRYPOINT_IN_DOORMAN_CONTRACT_PAUSED',
      description: 'COMPOUND ENTRYPOINT IN DOORMAN CONTRACT PAUSED',
    },
  ],
  [
    209,
    {
      message: 'error_FARM_CLAIM_ENTRYPOINT_IN_DOORMAN_CONTRACT_PAUSED',
      description: 'FARM CLAIM ENTRYPOINT IN DOORMAN CONTRACT PAUSED',
    },
  ],
  [
    210,
    {
      message: 'error_ON_VAULT_DEPOSIT_STAKE_ENTRYPOINT_IN_DOORMAN_CONTRACT_PAUSED',
      description: 'ON VAULT DEPOSIT STAKE ENTRYPOINT IN DOORMAN CONTRACT PAUSED',
    },
  ],
  [
    211,
    {
      message: 'error_ON_VAULT_WITHDRAW_STAKE_ENTRYPOINT_IN_DOORMAN_CONTRACT_PAUSED',
      description: 'ON VAULT WITHDRAW STAKE ENTRYPOINT IN DOORMAN CONTRACT PAUSED',
    },
  ],
  [
    212,
    {
      message: 'error_ON_VAULT_LIQUIDATE_STAKE_ENTRYPOINT_IN_DOORMAN_CONTRACT_PAUSED',
      description: 'ON VAULT LIQUIDATE STAKE ENTRYPOINT IN DOORMAN CONTRACT PAUSED',
    },
  ],
  [
    213,
    {
      message: 'error_ALL_DOORMAN_CONTRACT_ENTRYPOINTS_SHOULD_BE_PAUSED_TO_MIGRATE_FUNDS',
      description: 'ALL DOORMAN CONTRACT ENTRYPOINTS SHOULD BE PAUSED TO MIGRATE FUNDS',
    },
  ],
  [
    214,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    215,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    216,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    217,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    218,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    219,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    220,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    221,
    {
      message: 'error_MIGRATE_FUNDS_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'MIGRATE FUNDS ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    222,
    {
      message: 'error_PAUSE_ALL_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'PAUSE ALL ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    223,
    {
      message: 'error_UNPAUSE_ALL_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'UNPAUSE ALL ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    224,
    {
      message: 'error_TOGGLE_PAUSE_ENTRYPOINT_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'TOGGLE PAUSE ENTRYPOINT ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    225,
    {
      message: 'error_STAKE_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'STAKE ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    226,
    {
      message: 'error_UNSTAKE_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'UNSTAKE ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    227,
    {
      message: 'error_COMPOUND_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'COMPOUND ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    228,
    {
      message: 'error_FARM_CLAIM_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'FARM CLAIM ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    229,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    230,
    {
      message: 'error_ON_VAULT_DEPOSIT_STAKED_MVK_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'ON VAULT DEPOSIT STAKED MVN ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    231,
    {
      message: 'error_ON_VAULT_WITHDRAW_STAKED_MVK_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'ON VAULT WITHDRAW STAKED MVN ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    232,
    {
      message: 'error_ON_VAULT_LIQUIDATE_STAKED_MVK_ENTRYPOINT_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'ON VAULT LIQUIDATE STAKED MVN ENTRYPOINT IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    233,
    {
      message: 'error_GET_ADMIN_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    234,
    {
      message: 'error_GET_CONFIG_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    235,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    236,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    237,
    {
      message: 'error_GET_BREAK_GLASS_CONFIG_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET BREAK GLASS CONFIG VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    238,
    {
      message: 'error_GET_USER_STAKE_BALANCE_OPT_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET USER STAKE BALANCE OPT VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    239,
    {
      message: 'error_GET_STAKED_MVK_TOTAL_SUPPLY_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET STAKED MVN TOTAL SUPPLY VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    240,
    {
      message: 'error_GET_UNCLAIMED_REWARDS_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET UNCLAIMED REWARDS VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    241,
    {
      message: 'error_GET_ACCUMULATED_FEES_PER_SHARE_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET ACCUMULATED FEES PER SHARE VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    242,
    {
      message: 'error_GET_STAKED_BALANCE_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET STAKED BALANCE VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    243,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    244,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_DOORMAN_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN DOORMAN CONTRACT NOT FOUND',
    },
  ],
  [
    245,
    {
      message: 'error_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    246,
    {
      message: 'error_ONLY_EMERGENCY_GOVERNANCE_CONTRACT_ALLOWED',
      description: 'ONLY EMERGENCY GOVERNANCE CONTRACT ALLOWED',
    },
  ],
  [247, { message: 'error_TAX_TREASURY_CONTRACT_NOT_FOUND', description: 'TAX TREASURY CONTRACT NOT FOUND' }],
  [
    248,
    {
      message: 'error_EMERGENCY_GOVERNANCE_ALREADY_IN_THE_PROCESS',
      description: 'EMERGENCY GOVERNANCE ALREADY IN THE PROCESS',
    },
  ],
  [
    249,
    {
      message: 'error_EMERGENCY_GOVERNANCE_NOT_IN_THE_PROCESS',
      description: 'EMERGENCY GOVERNANCE NOT IN THE PROCESS',
    },
  ],
  [250, { message: 'error_EMERGENCY_GOVERNANCE_NOT_FOUND', description: 'EMERGENCY GOVERNANCE NOT FOUND' }],
  [251, { message: 'error_EMERGENCY_GOVERNANCE_DROPPED', description: 'EMERGENCY GOVERNANCE DROPPED' }],
  [252, { message: 'error_EMERGENCY_GOVERNANCE_EXECUTED', description: 'EMERGENCY GOVERNANCE EXECUTED' }],
  [
    253,
    {
      message: 'error_EMERGENCY_GOVERNANCE_VOTE_ALEADY_REGISTERED',
      description: 'EMERGENCY GOVERNANCE VOTE ALEADY REGISTERED',
    },
  ],
  [
    254,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    255,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    256,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    257,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    258,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    259,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    260,
    {
      message: 'error_TRIGGER_EMERGENCY_CONTROL_ENTRYPOINT_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'TRIGGER EMERGENCY CONTROL ENTRYPOINT IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    261,
    {
      message: 'error_VOTE_FOR_EMERGENCY_CONTROL_ENTRYPOINT_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'VOTE FOR EMERGENCY CONTROL ENTRYPOINT IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    262,
    {
      message: 'error_DROP_EMERGENCY_GOVERNANCE_ENTRYPOINT_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'DROP EMERGENCY GOVERNANCE ENTRYPOINT IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    263,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    264,
    {
      message: 'error_GET_ADMIN_VIEW_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    265,
    {
      message: 'error_GET_CONFIG_VIEW_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    266,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    267,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    268,
    {
      message: 'error_GET_EMERGENCY_GOVERNANCE_OPT_VIEW_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET EMERGENCY GOVERNANCE OPT VIEW IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    269,
    {
      message: 'error_GET_CURRENT_EMERGENCY_GOVERNANCE_ID_VIEW_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET CURRENT EMERGENCY GOVERNANCE ID VIEW IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    270,
    {
      message: 'error_GET_NEXT_EMERGENCY_GOVERNANCE_ID_VIEW_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET NEXT EMERGENCY GOVERNANCE ID VIEW IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    271,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    272,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_EMERGENCY_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN EMERGENCY GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [273, { message: 'error_FARM_CONTRACT_NOT_FOUND', description: 'FARM CONTRACT NOT FOUND' }],
  [274, { message: 'error_ONLY_FARM_CONTRACT_ALLOWED', description: 'ONLY FARM CONTRACT ALLOWED' }],
  [
    275,
    {
      message: 'error_ONLY_FARM_FACTORY_OR_COUNCIL_CONTRACT_ALLOWED',
      description: 'ONLY FARM FACTORY OR COUNCIL CONTRACT ALLOWED',
    },
  ],
  [
    276,
    {
      message: 'error_ONLY_ADMIN_OR_GOVERNANCE_OR_FARM_FACTORY_CONTRACT_ALLOWED',
      description: 'ONLY ADMIN OR GOVERNANCE OR FARM FACTORY CONTRACT ALLOWED',
    },
  ],
  [
    277,
    {
      message: 'error_FARM_SHOULD_BE_INFINITE_OR_HAVE_A_DURATION',
      description: 'FARM SHOULD BE INFINITE OR HAVE A DURATION',
    },
  ],
  [278, { message: 'error_DEPOSITOR_NOT_FOUND', description: 'DEPOSITOR NOT FOUND' }],
  [279, { message: 'error_WITHDRAWN_AMOUNT_TOO_HIGH', description: 'WITHDRAWN AMOUNT TOO HIGH' }],
  [280, { message: 'error_NO_FARM_REWARDS_TO_CLAIM', description: 'NO FARM REWARDS TO CLAIM' }],
  [281, { message: 'error_FARM_NOT_INITIATED', description: 'FARM NOT INITIATED' }],
  [282, { message: 'error_FARM_ALREADY_OPEN', description: 'FARM ALREADY OPEN' }],
  [283, { message: 'error_FARM_CLOSED', description: 'FARM CLOSED' }],
  [284, { message: 'error_DEPOSITOR_NOT_FOUND', description: 'DEPOSITOR NOT FOUND' }],
  [
    285,
    {
      message: 'error_CANNOT_TRANSFER_LP_TOKEN_USING_MISTAKEN_TRANSFER',
      description: 'CANNOT TRANSFER LP TOKEN USING MISTAKEN TRANSFER',
    },
  ],
  [
    286,
    {
      message: 'error_DEPOSIT_ENTRYPOINT_IN_FARM_CONTRACT_PAUSED',
      description: 'DEPOSIT ENTRYPOINT IN FARM CONTRACT PAUSED',
    },
  ],
  [
    287,
    {
      message: 'error_WITHDRAW_ENTRYPOINT_IN_FARM_CONTRACT_PAUSED',
      description: 'WITHDRAW ENTRYPOINT IN FARM CONTRACT PAUSED',
    },
  ],
  [
    288,
    {
      message: 'error_CLAIM_ENTRYPOINT_IN_FARM_CONTRACT_PAUSED',
      description: 'CLAIM ENTRYPOINT IN FARM CONTRACT PAUSED',
    },
  ],
  [
    289,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    290,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    291,
    {
      message: 'error_SET_NAME_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'SET NAME ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    292,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    293,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    294,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    295,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    296,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    297,
    {
      message: 'error_UPDATE_BLOCKS_PER_MINUTE_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'UPDATE BLOCKS PER MINUTE ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    298,
    {
      message: 'error_INIT_FARM_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'INIT FARM ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    299,
    {
      message: 'error_CLOSE_FARM_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'CLOSE FARM ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    300,
    {
      message: 'error_PAUSE_ALL_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'PAUSE ALL ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    301,
    {
      message: 'error_UNPAUSE_ALL_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'UNPAUSE ALL ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    302,
    {
      message: 'error_TOGGLE_PAUSE_ENTRYPOINT_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'TOGGLE PAUSE ENTRYPOINT ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    303,
    {
      message: 'error_DEPOSIT_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'DEPOSIT ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    304,
    {
      message: 'error_WITHDRAW_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'WITHDRAW ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    305,
    {
      message: 'error_CLAIM_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'CLAIM ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    306,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    307,
    {
      message: 'error_GET_ADMIN_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    308,
    {
      message: 'error_GET_NAME_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET NAME VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    309,
    {
      message: 'error_GET_CONFIG_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    310,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    311,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    312,
    {
      message: 'error_GET_BREAK_GLASS_CONFIG_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET BREAK GLASS CONFIG VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    313,
    {
      message: 'error_GET_LAST_BLOCK_UPDATE_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET LAST BLOCK UPDATE VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    314,
    {
      message: 'error_GET_ACCUMULATED_REWARDS_PER_SHARE_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET ACCUMULATED REWARDS PER SHARE VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    315,
    {
      message: 'error_GET_CLAIMED_REWARDS_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET CLAIMED REWARDS VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    316,
    {
      message: 'error_GET_DEPOSITOR_OPT_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET DEPOSITOR OPT VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    317,
    {
      message: 'error_GET_OPEN_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET OPEN VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    318,
    {
      message: 'error_GET_INIT_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET INIT VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    319,
    {
      message: 'error_GET_INIT_BLOCK_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET INIT BLOCK VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    320,
    {
      message: 'error_GET_MIN_BLOCK_TIME_SNAPSHOT_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET MIN BLOCK TIME SNAPSHOT VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    321,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [
    322,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_FARM_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN FARM CONTRACT NOT FOUND',
    },
  ],
  [323, { message: 'error_FARM_FACTORY_CONTRACT_NOT_FOUND', description: 'FARM FACTORY CONTRACT NOT FOUND' }],
  [324, { message: 'error_ONLY_FARM_FACTORY_CONTRACT_ALLOWED', description: 'ONLY FARM FACTORY CONTRACT ALLOWED' }],
  [
    325,
    {
      message: 'error_ONLY_ADMIN_OR_FARM_FACTORY_CONTRACT_ALLOWED',
      description: 'ONLY ADMIN OR FARM FACTORY CONTRACT ALLOWED',
    },
  ],
  [326, { message: 'error_FARM_ALREADY_TRACKED', description: 'FARM ALREADY TRACKED' }],
  [327, { message: 'error_FARM_NOT_TRACKED', description: 'FARM NOT TRACKED' }],
  [
    328,
    {
      message: 'error_CREATE_FARM_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_PAUSED',
      description: 'CREATE FARM ENTRYPOINT IN FARM FACTORY CONTRACT PAUSED',
    },
  ],
  [
    329,
    {
      message: 'error_CREATE_FARM_M_TOKEN_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_PAUSED',
      description: 'CREATE FARM M TOKEN ENTRYPOINT IN FARM FACTORY CONTRACT PAUSED',
    },
  ],
  [
    330,
    {
      message: 'error_TRACK_FARM_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_PAUSED',
      description: 'TRACK FARM ENTRYPOINT IN FARM FACTORY CONTRACT PAUSED',
    },
  ],
  [
    331,
    {
      message: 'error_UNTRACK_FARM_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_PAUSED',
      description: 'UNTRACK FARM ENTRYPOINT IN FARM FACTORY CONTRACT PAUSED',
    },
  ],
  [
    332,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    333,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    334,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    335,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    336,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    337,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    338,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    339,
    {
      message: 'error_UPDATE_BLOCKS_PER_MINUTE_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE BLOCKS PER MINUTE ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    340,
    {
      message: 'error_PAUSE_ALL_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'PAUSE ALL ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    341,
    {
      message: 'error_UNPAUSE_ALL_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UNPAUSE ALL ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    342,
    {
      message: 'error_TOGGLE_PAUSE_ENTRYPOINT_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'TOGGLE PAUSE ENTRYPOINT ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    343,
    {
      message: 'error_CREATE_FARM_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'CREATE FARM ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    344,
    {
      message: 'error_TRACK_FARM_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'TRACK FARM ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    345,
    {
      message: 'error_UNTRACK_FARM_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UNTRACK FARM ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    346,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    347,
    {
      message: 'error_SET_PRODUCT_LAMBDA_ENTRYPOINT_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET PRODUCT LAMBDA ENTRYPOINT IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    348,
    {
      message: 'error_CHECK_FARM_EXISTS_VIEW_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'CHECK FARM EXISTS VIEW IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    349,
    {
      message: 'error_GET_ADMIN_VIEW_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    350,
    {
      message: 'error_GET_CONFIG_VIEW_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    351,
    {
      message: 'error_GET_BREAK_GLASS_CONFIG_VIEW_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET BREAK GLASS CONFIG VIEW IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    352,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    353,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    354,
    {
      message: 'error_GET_TRACKED_FARMS_VIEW_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET TRACKED FARMS VIEW IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    355,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    356,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    357,
    {
      message: 'error_GET_PRODUCT_LAMBDA_OPT_VIEW_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET PRODUCT LAMBDA OPT VIEW IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    358,
    {
      message: 'error_GET_PRODUCT_LAMBDA_LEDGER_VIEW_IN_FARM_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET PRODUCT LAMBDA LEDGER VIEW IN FARM FACTORY CONTRACT NOT FOUND',
    },
  ],
  [359, { message: 'error_GOVERNANCE_CONTRACT_NOT_FOUND', description: 'GOVERNANCE CONTRACT NOT FOUND' }],
  [360, { message: 'error_ONLY_GOVERNANCE_CONTRACT_ALLOWED', description: 'ONLY GOVERNANCE CONTRACT ALLOWED' }],
  [361, { message: 'error_PAYMENT_TREASURY_CONTRACT_NOT_FOUND', description: 'PAYMENT TREASURY CONTRACT NOT FOUND' }],
  [
    362,
    {
      message: 'error_PROPOSE_TAX_TREASURY_CONTRACT_NOT_FOUND',
      description: 'PROPOSE TAX TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    363,
    {
      message: 'error_ONLY_ACCESSIBLE_DURING_PROPOSAL_ROUND',
      description: 'ONLY ACCESSIBLE DURING PROPOSAL ROUND',
    },
  ],
  [364, { message: 'error_ONLY_ACCESSIBLE_DURING_VOTING_ROUND', description: 'ONLY ACCESSIBLE DURING VOTING ROUND' }],
  [
    365,
    {
      message: 'error_ONLY_ACCESSIBLE_DURING_TIMELOCK_ROUND',
      description: 'ONLY ACCESSIBLE DURING TIMELOCK ROUND',
    },
  ],
  [366, { message: 'error_SNAPSHOT_NOT_READY', description: 'SNAPSHOT NOT READY' }],
  [367, { message: 'error_SNAPSHOT_NOT_FOUND', description: 'SNAPSHOT NOT FOUND' }],
  [368, { message: 'error_HIGHEST_VOTED_PROPOSAL_NOT_FOUND', description: 'HIGHEST VOTED PROPOSAL NOT FOUND' }],
  [369, { message: 'error_PROPOSAL_NOT_FOUND', description: 'PROPOSAL NOT FOUND' }],
  [370, { message: 'error_PROPOSAL_LOCKED', description: 'PROPOSAL LOCKED' }],
  [371, { message: 'error_PROPOSAL_CANNOT_BE_EXECUTED_NOW', description: 'PROPOSAL CANNOT BE EXECUTED NOW' }],
  [372, { message: 'error_PROPOSAL_DROPPED', description: 'PROPOSAL DROPPED' }],
  [373, { message: 'error_PROPOSAL_EXECUTED', description: 'PROPOSAL EXECUTED' }],
  [374, { message: 'error_PROPOSAL_HAS_NO_DATA_TO_EXECUTE', description: 'PROPOSAL HAS NO DATA TO EXECUTE' }],
  [375, { message: 'error_PROPOSAL_DATA_NOT_FOUND', description: 'PROPOSAL DATA NOT FOUND' }],
  [376, { message: 'error_PAYMENT_DATA_NOT_FOUND', description: 'PAYMENT DATA NOT FOUND' }],
  [377, { message: 'error_PROPOSAL_EXECUTION_ALREADY_STARTED', description: 'PROPOSAL EXECUTION ALREADY STARTED' }],
  [378, { message: 'error_PROPOSAL_NOT_EXECUTED', description: 'PROPOSAL NOT EXECUTED' }],
  [379, { message: 'error_PROPOSAL_PAYMENTS_PROCESSED', description: 'PROPOSAL PAYMENTS PROCESSED' }],
  [380, { message: 'error_PROPOSAL_NOT_LOCKED', description: 'PROPOSAL NOT LOCKED' }],
  [381, { message: 'error_NO_PROPOSAL_TO_VOTE_FOR', description: 'NO PROPOSAL TO VOTE FOR' }],
  [382, { message: 'error_NO_PROPOSAL_TO_EXECUTE', description: 'NO PROPOSAL TO EXECUTE' }],
  [383, { message: 'error_VOTE_NOT_FOUND', description: 'VOTE NOT FOUND' }],
  [384, { message: 'error_PROPOSAL_REWARD_ALREADY_CLAIMED', description: 'PROPOSAL REWARD ALREADY CLAIMED' }],
  [385, { message: 'error_PROPOSAL_REWARD_CANNOT_BE_CLAIMED', description: 'PROPOSAL REWARD CANNOT BE CLAIMED' }],
  [386, { message: 'error_VOTE_ALREADY_RECORDED', description: 'VOTE ALREADY RECORDED' }],
  [387, { message: 'error_CURRENT_ROUND_NOT_FINISHED', description: 'CURRENT ROUND NOT FINISHED' }],
  [388, { message: 'error_MAX_PROPOSAL_REACHED', description: 'MAX PROPOSAL REACHED' }],
  [389, { message: 'error_DEVELOPER_NOT_WHITELISTED', description: 'DEVELOPER NOT WHITELISTED' }],
  [
    390,
    {
      message: 'error_AT_LEAST_ONE_WHITELISTED_DEVELOPER_REQUIRED',
      description: 'AT LEAST ONE WHITELISTED DEVELOPER REQUIRED',
    },
  ],
  [
    391,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    392,
    {
      message: 'error_SET_GOVERNANCE_PROXY_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE PROXY ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    393,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    394,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    395,
    {
      message: 'error_UPDATE_WHITELIST_DEVELOPERS_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST DEVELOPERS ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    396,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    397,
    {
      message: 'error_UPDATE_WHITELIST_TOKEN_CONTRACTS_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST TOKEN CONTRACTS ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    398,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    399,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    400,
    {
      message: 'error_SET_CONTRACT_ADMIN_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'SET CONTRACT ADMIN ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    401,
    {
      message: 'error_SET_CONTRACT_GOVERNANCE_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'SET CONTRACT GOVERNANCE ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    402,
    {
      message: 'error_BREAK_GLASS_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'BREAK GLASS ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    403,
    {
      message: 'error_PROPAGATE_BREAK_GLASS_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'PROPAGATE BREAK GLASS ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    404,
    {
      message: 'error_UPDATE_SATELLITE_SNAPSHOT_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'UPDATE SATELLITE SNAPSHOT ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    405,
    {
      message: 'error_START_NEXT_ROUND_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'START NEXT ROUND ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    406,
    {
      message: 'error_PROPOSE_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'PROPOSE ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    407,
    {
      message: 'error_PROPOSAL_ROUND_VOTE_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'PROPOSAL ROUND VOTE ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    408,
    {
      message: 'error_ADD_UPDATE_PROPOSAL_DATA_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'ADD UPDATE PROPOSAL DATA ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    409,
    {
      message: 'error_ADD_UPDATE_PAYMENT_DATA_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'ADD UPDATE PAYMENT DATA ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    410,
    {
      message: 'error_LOCK_PROPOSAL_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'LOCK PROPOSAL ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    411,
    {
      message: 'error_VOTING_ROUND_VOTE_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'VOTING ROUND VOTE ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    412,
    {
      message: 'error_EXECUTE_PROPOSAL_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'EXECUTE PROPOSAL ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    413,
    {
      message: 'error_PROCESS_PROPOSAL_PAYMENT_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'PROCESS PROPOSAL PAYMENT ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    414,
    {
      message: 'error_PROCESS_PROPOSAL_SINGLE_DATA_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'PROCESS PROPOSAL SINGLE DATA ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    415,
    {
      message: 'error_DROP_PROPOSAL_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'DROP PROPOSAL ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    416,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    417,
    {
      message: 'error_GET_ADMIN_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    418,
    {
      message: 'error_GET_CONFIG_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    419,
    {
      message: 'error_GET_GOVERNANCE_PROXY_ADDRESS_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET GOVERNANCE PROXY ADDRESS VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    420,
    {
      message: 'error_GET_WHITELIST_DEVELOPERS_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST DEVELOPERS VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    421,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    422,
    {
      message: 'error_GET_GENERAL_CONTRACT_OPT_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACT OPT VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    423,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    424,
    {
      message: 'error_GET_PROPOSAL_OPT_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET PROPOSAL OPT VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    425,
    {
      message: 'error_GET_SNAPSHOT_OPT_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET SNAPSHOT OPT VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    426,
    {
      message: 'error_GET_PROPOSAL_REWARD_OPT_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET PROPOSAL REWARD OPT VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    427,
    {
      message: 'error_GET_SNAPSHOT_OPT_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET SNAPSHOT OPT VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    428,
    {
      message: 'error_GET_CURRENT_CYCLE_INFO_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET CURRENT CYCLE INFO VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    429,
    {
      message: 'error_GET_CYCLE_PROPOSALS_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET CYCLE PROPOSALS VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    430,
    {
      message: 'error_GET_CYCLE_PROPOSER_OPT_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET CYCLE PROPOSER OPT VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    431,
    {
      message: 'error_GET_ROUND_VOTE_OPT_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET ROUND VOTE OPT VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    432,
    {
      message: 'error_GET_NEXT_PROPOSAL_ID_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET NEXT PROPOSAL ID VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    433,
    {
      message: 'error_GET_CYCLE_COUNTER_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET CYCLE COUNTER VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    434,
    {
      message: 'error_GET_CYCLE_HIGHEST_VOTED_PROPOSAL_ID_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET CYCLE HIGHEST VOTED PROPOSAL ID VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    435,
    {
      message: 'error_GET_TIMELOCK_PROPOSAL_ID_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET TIMELOCK PROPOSAL ID VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    436,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    437,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_GOVERNANCE_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN GOVERNANCE CONTRACT NOT FOUND',
    },
  ],
  [
    438,
    {
      message: 'error_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    439,
    {
      message: 'error_ONLY_GOVERNANCE_FINANCIAL_CONTRACT_ALLOWED',
      description: 'ONLY GOVERNANCE FINANCIAL CONTRACT ALLOWED',
    },
  ],
  [
    440,
    {
      message: 'error_ONLY_ADMIN_OR_GOVERNANCE_FINANCIAL_CONTRACT_ALLOWED',
      description: 'ONLY ADMIN OR GOVERNANCE FINANCIAL CONTRACT ALLOWED',
    },
  ],
  [441, { message: 'error_FINANCIAL_REQUEST_NOT_FOUND', description: 'FINANCIAL REQUEST NOT FOUND' }],
  [442, { message: 'error_FINANCIAL_REQUEST_EXECUTED', description: 'FINANCIAL REQUEST EXECUTED' }],
  [443, { message: 'error_FINANCIAL_REQUEST_EXPIRED', description: 'FINANCIAL REQUEST EXPIRED' }],
  [444, { message: 'error_FINANCIAL_REQUEST_DROPPED', description: 'FINANCIAL REQUEST DROPPED' }],
  [445, { message: 'error_FINANCIAL_REQUEST_SNAPSHOT_NOT_FOUND', description: 'FINANCIAL REQUEST SNAPSHOT NOT FOUND' }],
  [
    446,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    447,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    448,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    449,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    450,
    {
      message: 'error_UPDATE_WHITELIST_TOKEN_CONTRACTS_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST TOKEN CONTRACTS ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    451,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    452,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    453,
    {
      message: 'error_REQUEST_TOKENS_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'REQUEST TOKENS ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    454,
    {
      message: 'error_REQUEST_MINT_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'REQUEST MINT ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    455,
    {
      message: 'error_SET_CONTRACT_BAKER_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'SET CONTRACT BAKER ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    456,
    {
      message: 'error_DROP_FINANCIAL_REQUEST_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'DROP FINANCIAL REQUEST ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    457,
    {
      message: 'error_VOTE_FOR_REQUEST_ENTRYPOINT_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'VOTE FOR REQUEST ENTRYPOINT IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    458,
    {
      message: 'error_GET_ADMIN_VIEW_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    459,
    {
      message: 'error_GET_CONFIG_VIEW_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    460,
    {
      message: 'error_GET_GOVERNANCE_ADDRESS_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GET GOVERNANCE ADDRESS IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    461,
    {
      message: 'error_GET_WHITELIST_TOKEN_CONTRACTS_VIEW_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST TOKEN CONTRACTS VIEW IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    462,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    463,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    464,
    {
      message: 'error_GET_FINANCIAL_REQUEST_OPT_VIEW_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GET FINANCIAL REQUEST OPT VIEW IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    465,
    {
      message: 'error_GET_FINANCIAL_REQUEST_COUNTER_VIEW_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GET FINANCIAL REQUEST COUNTER VIEW IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    466,
    {
      message: 'error_GET_FINANCIAL_REQUEST_VOTER_OPT_VIEW_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GET FINANCIAL REQUEST VOTER OPT VIEW IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    467,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [
    468,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_GOVERNANCE_FINANCIAL_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN GOVERNANCE FINANCIAL CONTRACT NOT FOUND',
    },
  ],
  [469, { message: 'error_GOVERNANCE_PROXY_CONTRACT_NOT_FOUND', description: 'GOVERNANCE PROXY CONTRACT NOT FOUND' }],
  [
    470,
    {
      message: 'error_ONLY_GOVERNANCE_PROXY_CONTRACT_ALLOWED',
      description: 'ONLY GOVERNANCE PROXY CONTRACT ALLOWED',
    },
  ],
  [
    471,
    {
      message: 'error_UNABLE_TO_UNPACK_GOVERNANCE_ACTION_LAMBDA',
      description: 'UNABLE TO UNPACK GOVERNANCE ACTION LAMBDA',
    },
  ],
  [
    472,
    {
      message: 'error_DATA_PACKING_HELPER_ENTRYPOINT_SHOULD_NOT_BE_CALLED',
      description: 'DATA PACKING HELPER ENTRYPOINT SHOULD NOT BE CALLED',
    },
  ],
  [
    473,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_GOVERNANCE_PROXY_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN GOVERNANCE PROXY CONTRACT NOT FOUND',
    },
  ],
  [
    474,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_GOVERNANCE_PROXY_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN GOVERNANCE PROXY CONTRACT NOT FOUND',
    },
  ],
  [
    475,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_GOVERNANCE_PROXY_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN GOVERNANCE PROXY CONTRACT NOT FOUND',
    },
  ],
  [
    476,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_GOVERNANCE_PROXY_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN GOVERNANCE PROXY CONTRACT NOT FOUND',
    },
  ],
  [
    477,
    {
      message: 'error_EXECUTE_GOVERNANCE_ACTION_ENTRYPOINT_IN_GOVERNANCE_PROXY_CONTRACT_NOT_FOUND',
      description: 'EXECUTE GOVERNANCE ACTION ENTRYPOINT IN GOVERNANCE PROXY CONTRACT NOT FOUND',
    },
  ],
  [
    478,
    {
      message: 'error_DATA_PACKING_HELPER_ENTRYPOINT_IN_GOVERNANCE_PROXY_CONTRACT_NOT_FOUND',
      description: 'DATA PACKING HELPER ENTRYPOINT IN GOVERNANCE PROXY CONTRACT NOT FOUND',
    },
  ],
  [
    479,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_GOVERNANCE_PROXY_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN GOVERNANCE PROXY CONTRACT NOT FOUND',
    },
  ],
  [
    480,
    {
      message: 'error_GET_ADMIN_VIEW_IN_GOVERNANCE_PROXY_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN GOVERNANCE PROXY CONTRACT NOT FOUND',
    },
  ],
  [
    481,
    {
      message: 'error_GET_GOVERNANCE_VIEW_IN_GOVERNANCE_PROXY_CONTRACT_NOT_FOUND',
      description: 'GET GOVERNANCE VIEW IN GOVERNANCE PROXY CONTRACT NOT FOUND',
    },
  ],
  [482, { message: 'error_TREASURY_CONTRACT_NOT_FOUND', description: 'TREASURY CONTRACT NOT FOUND' }],
  [483, { message: 'error_ONLY_TREASURY_CONTRACT_ALLOWED', description: 'ONLY TREASURY CONTRACT ALLOWED' }],
  [
    484,
    {
      message: 'error_ONLY_ADMIN_OR_TREASURY_FACTORY_CONTRACT_ALLOWED',
      description: 'ONLY ADMIN OR TREASURY FACTORY CONTRACT ALLOWED',
    },
  ],
  [
    485,
    {
      message: 'error_TRANSFER_ENTRYPOINT_IN_TREASURY_CONTRACT_PAUSED',
      description: 'TRANSFER ENTRYPOINT IN TREASURY CONTRACT PAUSED',
    },
  ],
  [
    486,
    {
      message: 'error_MINT_MVK_AND_TRANSFER_ENTRYPOINT_IN_TREASURY_CONTRACT_PAUSED',
      description: 'MINT MVK AND TRANSFER ENTRYPOINT IN TREASURY CONTRACT PAUSED',
    },
  ],
  [
    487,
    {
      message: 'error_UPDATE_MVK_OPERATORS_ENTRYPOINT_IN_TREASURY_CONTRACT_PAUSED',
      description: 'UPDATE MVK OPERATORS ENTRYPOINT IN TREASURY CONTRACT PAUSED',
    },
  ],
  [
    488,
    {
      message: 'error_STAKE_MVK_ENTRYPOINT_IN_TREASURY_CONTRACT_PAUSED',
      description: 'STAKE MVN ENTRYPOINT IN TREASURY CONTRACT PAUSED',
    },
  ],
  [
    489,
    {
      message: 'error_UNSTAKE_MVK_ENTRYPOINT_IN_TREASURY_CONTRACT_PAUSED',
      description: 'UNSTAKE MVN ENTRYPOINT IN TREASURY CONTRACT PAUSED',
    },
  ],
  [
    490,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    491,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    492,
    {
      message: 'error_SET_BAKER_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'SET BAKER ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    493,
    {
      message: 'error_SET_NAME_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'SET NAME ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    494,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    495,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    496,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    497,
    {
      message: 'error_UPDATE_WHITELIST_TOKEN_CONTRACTS_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST TOKEN CONTRACTS ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    498,
    {
      message: 'error_UPDATE_WHITELIST_TOKEN_CONTRACTS_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST TOKEN CONTRACTS ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    499,
    {
      message: 'error_PAUSE_ALL_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'PAUSE ALL ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    500,
    {
      message: 'error_UNPAUSE_ALL_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'UNPAUSE ALL ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    501,
    {
      message: 'error_TOGGLE_PAUSE_ENTRYPOINT_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'TOGGLE PAUSE ENTRYPOINT ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    502,
    {
      message: 'error_TRANSFER_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'TRANSFER ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    503,
    {
      message: 'error_MINT_MVK_AND_TRANSFER_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'MINT MVK AND TRANSFER ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    504,
    {
      message: 'error_UPDATE_MVK_OPERATORS_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'UPDATE MVK OPERATORS ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    505,
    {
      message: 'error_STAKE_MVK_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'STAKE MVN ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    506,
    {
      message: 'error_UNSTAKE_MVK_ENTRYPOINT_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'UNSTAKE MVN ENTRYPOINT IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    507,
    {
      message: 'error_GET_ADMIN_VIEW_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    508,
    {
      message: 'error_GET_NAME_VIEW_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'GET NAME VIEW IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    509,
    {
      message: 'error_GET_BREAK_GLASS_CONFIG_VIEW_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'GET BREAK GLASS CONFIG VIEW IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    510,
    {
      message: 'error_GET_WHITELIST_TOKEN_CONTRACTS_VIEW_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST TOKEN CONTRACTS VIEW IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    511,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    512,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    513,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [
    514,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_TREASURY_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN TREASURY CONTRACT NOT FOUND',
    },
  ],
  [515, { message: 'error_TREASURY_FACTORY_CONTRACT_NOT_FOUND', description: 'TREASURY FACTORY CONTRACT NOT FOUND' }],
  [
    516,
    {
      message: 'error_ONLY_TREASURY_FACTORY_CONTRACT_ALLOWED',
      description: 'ONLY TREASURY FACTORY CONTRACT ALLOWED',
    },
  ],
  [517, { message: 'error_TREASURY_ALREADY_TRACKED', description: 'TREASURY ALREADY TRACKED' }],
  [518, { message: 'error_TREASURY_NOT_TRACKED', description: 'TREASURY NOT TRACKED' }],
  [
    519,
    {
      message: 'error_CREATE_TREASURY_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_PAUSED',
      description: 'CREATE TREASURY ENTRYPOINT IN TREASURY FACTORY CONTRACT PAUSED',
    },
  ],
  [
    520,
    {
      message: 'error_TRACK_TREASURY_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_PAUSED',
      description: 'TRACK TREASURY ENTRYPOINT IN TREASURY FACTORY CONTRACT PAUSED',
    },
  ],
  [
    521,
    {
      message: 'error_UNTRACK_TREASURY_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_PAUSED',
      description: 'UNTRACK TREASURY ENTRYPOINT IN TREASURY FACTORY CONTRACT PAUSED',
    },
  ],
  [
    522,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    523,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    524,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    525,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    526,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    527,
    {
      message: 'error_UPDATE_WHITELIST_TOKEN_CONTRACTS_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST TOKEN CONTRACTS ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    528,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    529,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    530,
    {
      message: 'error_PAUSE_ALL_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'PAUSE ALL ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    531,
    {
      message: 'error_UNPAUSE_ALL_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UNPAUSE ALL ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    532,
    {
      message: 'error_TOGGLE_PAUSE_ENTRYPOINT_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'TOGGLE PAUSE ENTRYPOINT ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    533,
    {
      message: 'error_CREATE_TREASURY_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'CREATE TREASURY ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    534,
    {
      message: 'error_TRACK_TREASURY_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'TRACK TREASURY ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    535,
    {
      message: 'error_UNTRACK_TREASURY_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UNTRACK TREASURY ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    536,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    537,
    {
      message: 'error_SET_PRODUCT_LAMBDA_ENTRYPOINT_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET PRODUCT LAMBDA ENTRYPOINT IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    538,
    {
      message: 'error_CHECK_TREASURY_EXISTS_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'CHECK TREASURY EXISTS VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    539,
    {
      message: 'error_GET_ADMIN_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    540,
    {
      message: 'error_GET_CONFIG_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    541,
    {
      message: 'error_GET_TRACKED_TREASURIES_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET TRACKED TREASURIES VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    542,
    {
      message: 'error_GET_BREAK_GLASS_CONFIG_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET BREAK GLASS CONFIG VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    543,
    {
      message: 'error_GET_WHITELIST_TOKEN_CONTRACTS_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST TOKEN CONTRACTS VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    544,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    545,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    546,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    547,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    548,
    {
      message: 'error_GET_PRODUCT_LAMBDA_OPT_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET PRODUCT LAMBDA OPT VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    549,
    {
      message: 'error_GET_PRODUCT_LAMBDA_LEDGER_VIEW_IN_TREASURY_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET PRODUCT LAMBDA LEDGER VIEW IN TREASURY FACTORY CONTRACT NOT FOUND',
    },
  ],
  [550, { message: 'error_VESTING_CONTRACT_NOT_FOUND', description: 'VESTING CONTRACT NOT FOUND' }],
  [551, { message: 'error_ONLY_VESTING_CONTRACT_ALLOWED', description: 'ONLY VESTING CONTRACT ALLOWED' }],
  [552, { message: 'error_NO_VESTING_REWARDS_TO_CLAIM', description: 'NO VESTING REWARDS TO CLAIM' }],
  [553, { message: 'error_CANNOT_CLAIM_VESTING_REWARDS_NOW', description: 'CANNOT CLAIM VESTING REWARDS NOW' }],
  [554, { message: 'error_VESTING_IN_MONTHS_TOO_SHORT', description: 'VESTING IN MONTHS TOO SHORT' }],
  [555, { message: 'error_CLIFF_PERIOD_TOO_LONG', description: 'CLIFF PERIOD TOO LONG' }],
  [556, { message: 'error_VESTEE_ALREADY_EXISTS', description: 'VESTEE ALREADY EXISTS' }],
  [557, { message: 'error_VESTEE_NOT_FOUND', description: 'VESTEE NOT FOUND' }],
  [558, { message: 'error_VESTEE_LOCKED', description: 'VESTEE LOCKED' }],
  [
    559,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    560,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    561,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    562,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    563,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    564,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    565,
    {
      message: 'error_ADD_VESTEE_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'ADD VESTEE ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    566,
    {
      message: 'error_REMOVE_VESTEE_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'REMOVE VESTEE ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    567,
    {
      message: 'error_UPDATE_VESTEE_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'UPDATE VESTEE ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    568,
    {
      message: 'error_TOGGLE_VESTEE_LOCK_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'TOGGLE VESTEE LOCK ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    569,
    {
      message: 'error_CLAIM_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'CLAIM ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    570,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    571,
    {
      message: 'error_GET_ADMIN_VIEW_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    572,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    573,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    574,
    {
      message: 'error_GET_TOTAL_VESTED_AMOUNT_VIEW_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'GET TOTAL VESTED AMOUNT VIEW IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    575,
    {
      message: 'error_GET_VESTEE_BALANCE_VIEW_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'GET VESTEE BALANCE VIEW IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    576,
    {
      message: 'error_GET_VESTEE_OPT_VIEW_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'GET VESTEE OPT VIEW IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    577,
    {
      message: 'error_GET_TOTAL_VESTED_VIEW_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'GET TOTAL VESTED VIEW IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    578,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [
    579,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_VESTING_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN VESTING CONTRACT NOT FOUND',
    },
  ],
  [580, { message: 'error_AGGREGATOR_CONTRACT_NOT_FOUND', description: 'AGGREGATOR CONTRACT NOT FOUND' }],
  [581, { message: 'error_ONLY_AGGREGATOR_CONTRACT_ALLOWED', description: 'ONLY AGGREGATOR CONTRACT ALLOWED' }],
  [582, { message: 'error_AGGREGATOR_CONTRACT_EXISTS', description: 'AGGREGATOR CONTRACT EXISTS' }],
  [583, { message: 'error_ONLY_AUTHORIZED_ORACLES_ALLOWED', description: 'ONLY AUTHORIZED ORACLES ALLOWED' }],
  [
    584,
    {
      message: 'error_ONLY_ADMIN_OR_GOVERNANCE_SATELLITE_ALLOWED',
      description: 'ONLY ADMIN OR GOVERNANCE SATELLITE ALLOWED',
    },
  ],
  [
    585,
    {
      message: 'error_ONLY_ADMINISTRATOR_OR_GOVERNANCE_SATELLITE_OR_AGGREGATOR_FACTORY_CONTRACT_ALLOWED',
      description: 'ONLY ADMINISTRATOR OR GOVERNANCE SATELLITE OR AGGREGATOR FACTORY CONTRACT ALLOWED',
    },
  ],
  [
    586,
    {
      message: 'error_ONLY_ADMINISTRATOR_OR_GOVERNANCE_OR_GOVERNANCE_SATELLITE_OR_AGGREGATOR_FACTORY_CONTRACT_ALLOWED',
      description: 'ONLY ADMINISTRATOR OR GOVERNANCE OR GOVERNANCE SATELLITE OR AGGREGATOR FACTORY CONTRACT ALLOWED',
    },
  ],
  [587, { message: 'error_ORACLE_ALREADY_ADDED_TO_AGGREGATOR', description: 'ORACLE ALREADY ADDED TO AGGREGATOR' }],
  [588, { message: 'error_ORACLE_NOT_PRESENT_IN_AGGREGATOR', description: 'ORACLE NOT PRESENT IN AGGREGATOR' }],
  [
    589,
    {
      message: 'error_UPDATE_DATA_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_PAUSED',
      description: 'UPDATE DATA ENTRYPOINT IN AGGREGATOR CONTRACT PAUSED',
    },
  ],
  [
    590,
    {
      message: 'error_WITHDRAW_REWARD_XTZ_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_PAUSED',
      description: 'WITHDRAW REWARD XTZ ENTRYPOINT IN AGGREGATOR CONTRACT PAUSED',
    },
  ],
  [
    591,
    {
      message: 'error_WITHDRAW_REWARD_STAKED_MVK_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_PAUSED',
      description: 'WITHDRAW REWARD STAKED MVN ENTRYPOINT IN AGGREGATOR CONTRACT PAUSED',
    },
  ],
  [
    592,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    593,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    594,
    {
      message: 'error_SET_MAINTAINER_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'SET MAINTAINER ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    595,
    {
      message: 'error_SET_NAME_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'SET NAME ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    596,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    597,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    598,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    599,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    600,
    {
      message: 'error_ADD_ORACLE_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'ADD ORACLE ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    601,
    {
      message: 'error_REMOVE_ORACLE_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'REMOVE ORACLE ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    602,
    {
      message: 'error_PAUSE_ALL_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'PAUSE ALL ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    603,
    {
      message: 'error_UNPAUSE_ALL_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'UNPAUSE ALL ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    604,
    {
      message: 'error_TOGGLE_PAUSE_ENTRYPOINT_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'TOGGLE PAUSE ENTRYPOINT ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    605,
    {
      message: 'error_REQUEST_RATE_UPDATE_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'REQUEST RATE UPDATE ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    606,
    {
      message: 'error_SET_OBSERVATION_REVEAL_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'SET OBSERVATION REVEAL ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    607,
    {
      message: 'error_WITHDRAW_REWARD_XTZ_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'WITHDRAW REWARD XTZ ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    608,
    {
      message: 'error_WITHDRAW_REWARD_STAKED_MVK_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'WITHDRAW REWARD STAKED MVN ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    609,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    610,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    611,
    {
      message: 'error_GET_ADMIN_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    612,
    {
      message: 'error_GET_NAME_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET NAME VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    613,
    {
      message: 'error_GET_CONFIG_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    614,
    {
      message: 'error_GET_GOVERNANCE_ADDRESS_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET GOVERNANCE ADDRESS VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    615,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    616,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    617,
    {
      message: 'error_GET_ORACLE_ADDRESSES_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET ORACLE ADDRESSES VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    618,
    {
      message: 'error_GET_ORACLE_REWARDS_STAKED_MVK_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET ORACLE REWARDS STAKED MVN VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    619,
    {
      message: 'error_GET_ORACLE_REWARDS_XTZ_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET ORACLE REWARDS XTZ VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    620,
    {
      message: 'error_GET_LAST_COMPLETED_DATA_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET LAST COMPLETED DATA VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    621,
    {
      message: 'error_GET_DECIMALS_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET DECIMALS VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    622,
    {
      message: 'error_GET_CONTRACT_NAME_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET CONTRACT NAME VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    623,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    624,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_AGGREGATOR_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN AGGREGATOR CONTRACT NOT FOUND',
    },
  ],
  [
    625,
    {
      message: 'error_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    626,
    {
      message: 'error_ONLY_AGGREGATOR_FACTORY_CONTRACT_ALLOWED',
      description: 'ONLY AGGREGATOR FACTORY CONTRACT ALLOWED',
    },
  ],
  [
    627,
    {
      message: 'error_ONLY_ADMIN_OR_AGGREGATOR_FACTORY_CONTRACT_ALLOWED',
      description: 'ONLY ADMIN OR AGGREGATOR FACTORY CONTRACT ALLOWED',
    },
  ],
  [628, { message: 'error_SENDER_IS_NOT_TRACKED_AGGREGATOR', description: 'SENDER IS NOT TRACKED AGGREGATOR' }],
  [
    629,
    {
      message: 'error_ACTION_FAILED_AS_SATELLITE_IS_NOT_REGISTERED',
      description: 'ACTION FAILED AS SATELLITE IS NOT REGISTERED',
    },
  ],
  [
    630,
    {
      message: 'error_ONLY_ADMIN_OR_GOVERNANCE_OR_AGGREGATOR_FACTORY_CONTRACT_ALLOWED',
      description: 'ONLY ADMIN OR GOVERNANCE OR AGGREGATOR FACTORY CONTRACT ALLOWED',
    },
  ],
  [631, { message: 'error_AGGREGATOR_ALREADY_TRACKED', description: 'AGGREGATOR ALREADY TRACKED' }],
  [632, { message: 'error_AGGREGATOR_NOT_TRACKED', description: 'AGGREGATOR NOT TRACKED' }],
  [633, { message: 'error_WRONG_SIGNATURES_MAP_SIZE', description: 'WRONG SIGNATURES MAP SIZE' }],
  [634, { message: 'error_WRONG_OBSERVATIONS_MAP_SIZE', description: 'WRONG OBSERVATIONS MAP SIZE' }],
  [635, { message: 'error_WRONG_SIGNATURE_IN_OBSERVATIONS_MAP', description: 'WRONG SIGNATURE IN OBSERVATIONS MAP' }],
  [
    636,
    {
      message: 'error_ACTION_FAILED_AS_ORACLE_IS_NOT_REGISTERED',
      description: 'ACTION FAILED AS ORACLE IS NOT REGISTERED',
    },
  ],
  [
    637,
    {
      message: 'error_WRONG_AGGREGATOR_ADDRESS_IN_OBSERVATIONS_MAP',
      description: 'WRONG AGGREGATOR ADDRESS IN OBSERVATIONS MAP',
    },
  ],
  [638, { message: 'error_OBSERVATION_MADE_BY_WRONG_ORACLE', description: 'OBSERVATION MADE BY WRONG ORACLE' }],
  [639, { message: 'error_DIFFERENT_EPOCH_IN_OBSERVATIONS_MAP', description: 'DIFFERENT EPOCH IN OBSERVATIONS MAP' }],
  [640, { message: 'error_DIFFERENT_ROUND_IN_OBSERVATIONS_MAP', description: 'DIFFERENT ROUND IN OBSERVATIONS MAP' }],
  [
    641,
    {
      message: 'error_EPOCH_SHOULD_BE_GREATER_THAN_PREVIOUS_RESULT',
      description: 'EPOCH SHOULD BE GREATER THAN PREVIOUS RESULT',
    },
  ],
  [
    642,
    {
      message: 'error_ROUND_SHOULD_BE_GREATER_THAN_PREVIOUS_RESULT',
      description: 'ROUND SHOULD BE GREATER THAN PREVIOUS RESULT',
    },
  ],
  [
    643,
    {
      message: 'error_CREATE_AGGREGATOR_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_PAUSED',
      description: 'CREATE AGGREGATOR ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT PAUSED',
    },
  ],
  [
    644,
    {
      message: 'error_TRACK_AGGREGATOR_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_PAUSED',
      description: 'TRACK AGGREGATOR ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT PAUSED',
    },
  ],
  [
    645,
    {
      message: 'error_UNTRACK_AGGREGATOR_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_PAUSED',
      description: 'UNTRACK AGGREGATOR ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT PAUSED',
    },
  ],
  [
    646,
    {
      message: 'error_DISTRIBUTE_REWARD_XTZ_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_PAUSED',
      description: 'DISTRIBUTE REWARD XTZ ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT PAUSED',
    },
  ],
  [
    647,
    {
      message: 'error_DISTRIBUTE_REWARD_STAKED_MVK_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_PAUSED',
      description: 'DISTRIBUTE REWARD STAKED MVN ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT PAUSED',
    },
  ],
  [
    648,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    649,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    650,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    651,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    652,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    653,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    654,
    {
      message: 'error_PAUSE_ALL_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'PAUSE ALL ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    655,
    {
      message: 'error_UNPAUSE_ALL_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UNPAUSE ALL ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    656,
    {
      message: 'error_TOGGLE_PAUSE_ENTRYPOINT_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'TOGGLE PAUSE ENTRYPOINT ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    657,
    {
      message: 'error_CREATE_AGGREGATOR_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'CREATE AGGREGATOR ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    658,
    {
      message: 'error_TRACK_AGGREGATOR_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'TRACK AGGREGATOR ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    659,
    {
      message: 'error_UNTRACK_AGGREGATOR_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'UNTRACK AGGREGATOR ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    660,
    {
      message: 'error_DISTRIBUTE_REWARD_XTZ_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'DISTRIBUTE REWARD XTZ ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    661,
    {
      message: 'error_DISTRIBUTE_REWARD_STAKED_MVK_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'DISTRIBUTE REWARD STAKED MVN ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    662,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    663,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    664,
    {
      message: 'error_SET_PRODUCT_LAMBDA_ENTRYPOINT_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'SET PRODUCT LAMBDA ENTRYPOINT IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    665,
    {
      message: 'error_GET_ADMIN_VIEW_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    666,
    {
      message: 'error_GET_CONFIG_VIEW_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    667,
    {
      message: 'error_GET_BREAK_GLASS_CONFIG_VIEW_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET BREAK GLASS CONFIG VIEW IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    668,
    {
      message: 'error_GET_GOVERNANCE_ADDRESS_VIEW_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET GOVERNANCE ADDRESS VIEW IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    669,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    670,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    671,
    {
      message: 'error_GET_TRACKED_AGGREGATORS_VIEW_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET TRACKED AGGREGATORS VIEW IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    672,
    {
      message: 'error_GET_AGGREGATOR_VIEW_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET AGGREGATOR VIEW IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    673,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    674,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_AGGREGATOR_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN AGGREGATOR FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    675,
    {
      message: 'error_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    676,
    {
      message: 'error_ONLY_ADMIN_OR_GOVERNANCE_SATELLITE_CONTRACT_ALLOWED',
      description: 'ONLY ADMIN OR GOVERNANCE SATELLITE CONTRACT ALLOWED',
    },
  ],
  [
    677,
    {
      message: 'error_ONLY_ADMINISTRATOR_OR_WHITELISTED_ADDRESSES_OR_AGGREGATOR_ALLOWED',
      description: 'ONLY ADMINISTRATOR OR WHITELISTED ADDRESSES OR AGGREGATOR ALLOWED',
    },
  ],
  [678, { message: 'error_ONLY_INITIATOR_CAN_DROP_ACTION', description: 'ONLY INITIATOR CAN DROP ACTION' }],
  [679, { message: 'error_GOVERNANCE_SATELLITE_ACTION_DROPPED', description: 'GOVERNANCE SATELLITE ACTION DROPPED' }],
  [
    680,
    {
      message: 'error_GOVERNANCE_SATELLITE_ACTION_NOT_FOUND',
      description: 'GOVERNANCE SATELLITE ACTION NOT FOUND',
    },
  ],
  [681, { message: 'error_GOVERNANCE_SATELLITE_ACTION_EXECUTED', description: 'GOVERNANCE SATELLITE ACTION EXECUTED' }],
  [682, { message: 'error_GOVERNANCE_SATELLITE_ACTION_EXPIRED', description: 'GOVERNANCE SATELLITE ACTION EXPIRED' }],
  [
    683,
    {
      message: 'error_GOVERNANCE_SATELLITE_ACTION_SNAPSHOT_NOT_FOUND',
      description: 'GOVERNANCE SATELLITE ACTION SNAPSHOT NOT FOUND',
    },
  ],
  [
    684,
    {
      message: 'error_GOVERNANCE_SATELLITE_ACTION_PARAMETER_NOT_FOUND',
      description: 'GOVERNANCE SATELLITE ACTION PARAMETER NOT FOUND',
    },
  ],
  [685, { message: 'error_INITIATOR_ACTIONS_NOT_FOUND', description: 'INITIATOR ACTIONS NOT FOUND' }],
  [
    686,
    {
      message: 'error_MAX_GOVERNANCE_SATELLITE_ACTION_REACHED',
      description: 'MAX GOVERNANCE SATELLITE ACTION REACHED',
    },
  ],
  [
    687,
    {
      message: 'error_ONLY_SATELLITES_ALLOWED_TO_INITIATE_GOVERNANCE_ACTION',
      description: 'ONLY SATELLITES ALLOWED TO INITIATE GOVERNANCE ACTION',
    },
  ],
  [
    688,
    {
      message: 'error_ONLY_SATELLITES_ALLOWED_TO_VOTE_FOR_GOVERNANCE_ACTION',
      description: 'ONLY SATELLITES ALLOWED TO VOTE FOR GOVERNANCE ACTION',
    },
  ],
  [
    689,
    {
      message: 'error_SNAPSHOT_STAKED_MVK_TOTAL_SUPPLY_NOT_FOUND',
      description: 'SNAPSHOT STAKED MVN TOTAL SUPPLY NOT FOUND',
    },
  ],
  [
    690,
    {
      message: 'error_SATELLITE_SUBSCRIBED_AGGREGATORS_NOT_FOUND',
      description: 'SATELLITE SUBSCRIBED AGGREGATORS NOT FOUND',
    },
  ],
  [691, { message: 'error_ORACLE_NOT_FOUND', description: 'ORACLE NOT FOUND' }],
  [692, { message: 'error_ORACLE_INFORMATION_NOT_FOUND', description: 'ORACLE INFORMATION NOT FOUND' }],
  [693, { message: 'error_AGGREGATOR_NEW_STATUS_NOT_FOUND', description: 'AGGREGATOR NEW STATUS NOT FOUND' }],
  [
    694,
    {
      message: 'error_AGGREGATOR_RECORD_IN_GOVERNANCE_SATELLITE_NOT_FOUND',
      description: 'AGGREGATOR RECORD IN GOVERNANCE SATELLITE NOT FOUND',
    },
  ],
  [695, { message: 'error_WRONG_AGGREGATOR_ADDRESS_PROVIDED', description: 'WRONG AGGREGATOR ADDRESS PROVIDED' }],
  [
    696,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    697,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    698,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    699,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    700,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    701,
    {
      message: 'error_UPDATE_GENERAL_CONTRACTS_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'UPDATE GENERAL CONTRACTS ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    702,
    {
      message: 'error_SUSPEND_SATELLITE_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'SUSPEND SATELLITE ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    703,
    {
      message: 'error_BAN_SATELLITE_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'BAN SATELLITE ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    704,
    {
      message: 'error_RESTORE_SATELLITE_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'RESTORE SATELLITE ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    705,
    {
      message: 'error_REMOVE_ALL_SATELLITE_ORACLES_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'REMOVE ALL SATELLITE ORACLES ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    706,
    {
      message: 'error_ADD_ORACLE_TO_AGGREGATOR_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'ADD ORACLE TO AGGREGATOR ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    707,
    {
      message: 'error_REMOVE_ORACLE_IN_AGGREGATOR_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'REMOVE ORACLE IN AGGREGATOR ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    708,
    {
      message: 'error_SET_AGGREGATOR_REFERENCE_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'SET AGGREGATOR REFERENCE ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    709,
    {
      message: 'error_TOGGLE_PAUSE_AGGREGATOR_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'TOGGLE PAUSE AGGREGATOR ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    710,
    {
      message: 'error_FIX_MISTAKEN_TRANSFER_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'FIX MISTAKEN TRANSFER ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    711,
    {
      message: 'error_DROP_ACTION_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'DROP ACTION ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    712,
    {
      message: 'error_VOTE_FOR_ACTION_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'VOTE FOR ACTION ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    713,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    714,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    715,
    {
      message: 'error_GET_ADMIN_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    716,
    {
      message: 'error_GET_CONFIG_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    717,
    {
      message: 'error_GET_GOVERNANCE_ADDRESS_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET GOVERNANCE ADDRESS VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    718,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    719,
    {
      message: 'error_GET_GENERAL_CONTRACTS_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET GENERAL CONTRACTS VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    720,
    {
      message: 'error_GET_GOVERNANCE_SATELLITE_ACTION_OPT_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET GOVERNANCE SATELLITE ACTION OPT VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    721,
    {
      message: 'error_GET_GOVERNANCE_SATELLITE_ACTION_COUNTER_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET GOVERNANCE SATELLITE ACTION COUNTER VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    722,
    {
      message: 'error_GET_GOVERNANCE_SATELLITE_VOTER_OPT_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET GOVERNANCE SATELLITE VOTER OPT VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    723,
    {
      message: 'error_GET_ACTIONS_INITATOR_OPT_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET ACTIONS INITATOR OPT VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    724,
    {
      message: 'error_GET_GOVERNANCE_CYCLE_SNAPSHOT_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET GOVERNANCE CYCLE SNAPSHOT VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    725,
    {
      message: 'error_GET_SATELLITE_ORACLE_RECORD_OPT_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET SATELLITE ORACLE RECORD OPT VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    726,
    {
      message: 'error_GET_AGGREGATOR_OPT_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET AGGREGATOR OPT VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    727,
    {
      message: 'error_GET_LAMBDA_OPT_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA OPT VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [
    728,
    {
      message: 'error_GET_LAMBDA_LEDGER_VIEW_IN_GOVERNANCE_SATELLITE_CONTRACT_NOT_FOUND',
      description: 'GET LAMBDA LEDGER VIEW IN GOVERNANCE SATELLITE CONTRACT NOT FOUND',
    },
  ],
  [729, { message: 'error_MVK_PAY_AMOUNT_NOT_MET', description: 'MVK PAY AMOUNT NOT MET' }],
  [730, { message: 'error_MIN_MVK_AMOUNT_NOT_REACHED', description: 'MIN MVK AMOUNT NOT REACHED' }],
  [731, { message: 'error_TOKEN_SALE_HAS_NOT_STARTED', description: 'TOKEN SALE HAS NOT STARTED' }],
  [732, { message: 'error_TOKEN_SALE_HAS_ENDED', description: 'TOKEN SALE HAS ENDED' }],
  [733, { message: 'error_TOKEN_SALE_HAS_NOT_ENDED', description: 'TOKEN SALE HAS NOT ENDED' }],
  [734, { message: 'error_TOKEN_SALE_IS_PAUSED', description: 'TOKEN SALE IS PAUSED' }],
  [735, { message: 'error_TOKEN_SALE_IS_NOT_PAUSED', description: 'TOKEN SALE IS NOT PAUSED' }],
  [736, { message: 'error_WHITELIST_SALE_HAS_NOT_STARTED', description: 'WHITELIST SALE HAS NOT STARTED' }],
  [737, { message: 'error_USER_IS_NOT_WHITELISTED', description: 'USER IS NOT WHITELISTED' }],
  [738, { message: 'error_MAX_AMOUNT_WHITELIST_WALLET_EXCEEDED', description: 'MAX AMOUNT WHITELIST WALLET EXCEEDED' }],
  [739, { message: 'error_MIN_AMOUNT_NOT_REACHED', description: 'MIN AMOUNT NOT REACHED' }],
  [740, { message: 'error_MAX_AMOUNT_CLAIMED', description: 'MAX AMOUNT CLAIMED' }],
  [741, { message: 'error_MAX_AMOUNT_PER_WALLET_TOTAL_EXCEEDED', description: 'MAX AMOUNT PER WALLET TOTAL EXCEEDED' }],
  [742, { message: 'error_MAX_AMOUNT_CAP_EXCEEDED', description: 'MAX AMOUNT CAP EXCEEDED' }],
  [743, { message: 'error_BUY_OPTION_NOT_FOUND', description: 'BUY OPTION NOT FOUND' }],
  [744, { message: 'error_USER_TOKEN_SALE_RECORD_NOT_FOUND', description: 'USER TOKEN SALE RECORD NOT FOUND' }],
  [
    745,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    746,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    747,
    {
      message: 'error_UPDATE_CONFIG_ENTRYPOINT_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'UPDATE CONFIG ENTRYPOINT IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    748,
    {
      message: 'error_SET_WHITELIST_DATETIME_ENTRYPOINT_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'SET WHITELIST DATETIME ENTRYPOINT IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    749,
    {
      message: 'error_ADD_TO_WHITELIST_ENTRYPOINT_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'ADD TO WHITELIST ENTRYPOINT IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    750,
    {
      message: 'error_REMOVE_FROM_WHITELIST_ENTRYPOINT_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'REMOVE FROM WHITELIST ENTRYPOINT IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    751,
    {
      message: 'error_START_SALE_ENTRYPOINT_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'START SALE ENTRYPOINT IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    752,
    {
      message: 'error_CLOSE_SALE_ENTRYPOINT_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'CLOSE SALE ENTRYPOINT IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    753,
    {
      message: 'error_PAUSE_SALE_ENTRYPOINT_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'PAUSE SALE ENTRYPOINT IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    754,
    {
      message: 'error_BUY_TOKENS_ENTRYPOINT_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'BUY TOKENS ENTRYPOINT IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    755,
    {
      message: 'error_CLAIM_TOKENS_ENTRYPOINT_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'CLAIM TOKENS ENTRYPOINT IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    756,
    {
      message: 'error_GET_ADMIN_VIEW_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    757,
    {
      message: 'error_GET_CONFIG_VIEW_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    758,
    {
      message: 'error_GET_TREASURY_ADDRESS_VIEW_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'GET TREASURY ADDRESS VIEW IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    759,
    {
      message: 'error_GET_WHITELISTED_ADDRESS_OPT_VIEW_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'GET WHITELISTED ADDRESS OPT VIEW IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    760,
    {
      message: 'error_GET_TOKEN_SALE_RECORD_OPT_VIEW_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'GET TOKEN SALE RECORD OPT VIEW IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    761,
    {
      message: 'error_GET_WHITELIST_START_TIMESTAMP_VIEW_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST START TIMESTAMP VIEW IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    762,
    {
      message: 'error_GET_WHITELIST_END_TIMESTAMP_VIEW_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST END TIMESTAMP VIEW IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    763,
    {
      message: 'error_GET_TOKEN_SALE_HAS_STARTED_VIEW_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'GET TOKEN SALE HAS STARTED VIEW IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    764,
    {
      message: 'error_GET_TOKEN_SALE_HAS_ENDED_VIEW_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'GET TOKEN SALE HAS ENDED VIEW IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    765,
    {
      message: 'error_GET_TOKEN_SALE_END_TIMESTAMP_VIEW_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'GET TOKEN SALE END TIMESTAMP VIEW IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    766,
    {
      message: 'error_GET_TOKEN_SALE_END_BLOCK_LEVEL_VIEW_IN_TOKEN_SALE_CONTRACT_NOT_FOUND',
      description: 'GET TOKEN SALE END BLOCK LEVEL VIEW IN TOKEN SALE CONTRACT NOT FOUND',
    },
  ],
  [
    767,
    {
      message: 'error_USDM_TOKEN_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'USDM TOKEN CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [
    768,
    {
      message: 'error_ONLY_USDM_TOKEN_CONTROLLER_CONTRACT_ALLOWED',
      description: 'ONLY USDM TOKEN CONTROLLER CONTRACT ALLOWED',
    },
  ],
  [769, { message: 'error_TOKEN_POOL_CONTRACT_NOT_FOUND', description: 'TOKEN POOL CONTRACT NOT FOUND' }],
  [
    770,
    {
      message: 'error_TRANSFER_ENTRYPOINT_IN_TOKEN_POOL_CONTRACT_NOT_FOUND',
      description: 'TRANSFER ENTRYPOINT IN TOKEN POOL CONTRACT NOT FOUND',
    },
  ],
  [
    771,
    {
      message: 'error_INSUFFICIENT_TOKENS_IN_TOKEN_POOL_TO_BE_BORROWED',
      description: 'INSUFFICIENT TOKENS IN TOKEN POOL TO BE BORROWED',
    },
  ],
  [
    772,
    {
      message: 'error_ON_BORROW_ENTRYPOINT_IN_TOKEN_POOL_CONTRACT_NOT_FOUND',
      description: 'ON BORROW ENTRYPOINT IN TOKEN POOL CONTRACT NOT FOUND',
    },
  ],
  [
    773,
    {
      message: 'error_ON_REPAY_ENTRYPOINT_IN_TOKEN_POOL_CONTRACT_NOT_FOUND',
      description: 'ON REPAY ENTRYPOINT IN TOKEN POOL CONTRACT NOT FOUND',
    },
  ],
  [774, { message: 'error_TOKEN_POOL_RESERVES_RATIO_NOT_MET', description: 'TOKEN POOL RESERVES RATIO NOT MET' }],
  [
    775,
    {
      message: 'error_INCORRECT_FINAL_TOTAL_BORROWED_AMOUNT',
      description: 'INCORRECT FINAL TOTAL BORROWED AMOUNT',
    },
  ],
  [776, { message: 'error_DEPOSITOR_RECORD_NOT_FOUND', description: 'DEPOSITOR RECORD NOT FOUND' }],
  [
    777,
    {
      message: 'error_UPDATE_REWARDS_ENTRYPOINT_IN_TOKEN_POOL_CONTRACT_NOT_FOUND',
      description: 'UPDATE REWARDS ENTRYPOINT IN TOKEN POOL CONTRACT NOT FOUND',
    },
  ],
  [778, { message: 'error_TOKEN_POOL_REWARD_CONTRACT_NOT_FOUND', description: 'TOKEN POOL REWARD CONTRACT NOT FOUND' }],
  [779, { message: 'error_TOKEN_POOL_REWARDS_RECORD_NOT_FOUND', description: 'TOKEN POOL REWARDS RECORD NOT FOUND' }],
  [
    780,
    {
      message: 'error_UPDATE_REWARDS_ENTRYPOINT_IN_TOKEN_POOL_REWARD_CONTRACT_PAUSED',
      description: 'UPDATE REWARDS ENTRYPOINT IN TOKEN POOL REWARD CONTRACT PAUSED',
    },
  ],
  [
    781,
    {
      message: 'error_CLAIM_REWARDS_ENTRYPOINT_IN_TOKEN_POOL_REWARD_CONTRACT_PAUSED',
      description: 'CLAIM REWARDS ENTRYPOINT IN TOKEN POOL REWARD CONTRACT PAUSED',
    },
  ],
  [
    782,
    {
      message: 'error_UPDATE_REWARDS_ENTRYPOINT_IN_TOKEN_POOL_REWARD_CONTRACT_NOT_FOUND',
      description: 'UPDATE REWARDS ENTRYPOINT IN TOKEN POOL REWARD CONTRACT NOT FOUND',
    },
  ],
  [
    783,
    {
      message: 'error_CLAIM_REWARDS_ENTRYPOINT_IN_TOKEN_POOL_REWARD_CONTRACT_NOT_FOUND',
      description: 'CLAIM REWARDS ENTRYPOINT IN TOKEN POOL REWARD CONTRACT NOT FOUND',
    },
  ],
  [
    784,
    {
      message: 'error_ONLY_WHITELISTED_LP_TOKEN_CONTRACT_ADDRESSES_ALLOWED',
      description: 'ONLY WHITELISTED LP TOKEN CONTRACT ADDRESSES ALLOWED',
    },
  ],
  [785, { message: 'error_NOT_AUTHORISED_TO_DEPOSIT_INTO_VAULT', description: 'NOT AUTHORISED TO DEPOSIT INTO VAULT' }],
  [
    786,
    {
      message: 'error_NOT_AUTHORISED_TO_WITHDRAW_FROM_VAULT',
      description: 'NOT AUTHORISED TO WITHDRAW FROM VAULT',
    },
  ],
  [787, { message: 'error_AMOUNT_NOT_EQUAL_TO_DEPOSIT', description: 'AMOUNT NOT EQUAL TO DEPOSIT' }],
  [788, { message: 'error_ONLY_VAULT_OWNER_ALLOWED', description: 'ONLY VAULT OWNER ALLOWED' }],
  [789, { message: 'error_INVALID_DEPOSITORS_CONFIG', description: 'INVALID DEPOSITORS CONFIG' }],
  [
    790,
    {
      message: 'error_INVALID_UPDATE_DEPOSITORS_CONFIGURATION',
      description: 'INVALID UPDATE DEPOSITORS CONFIGURATION',
    },
  ],
  [
    791,
    {
      message: 'error_VAULT_LAMBDA_NOT_FOUND_IN_VAULT_FACTORY_VAULT_LAMBDA_LEDGER',
      description: 'VAULT LAMBDA NOT FOUND IN VAULT FACTORY VAULT LAMBDA LEDGER',
    },
  ],
  [
    792,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_VAULT_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN VAULT CONTRACT NOT FOUND',
    },
  ],
  [
    793,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_VAULT_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN VAULT CONTRACT NOT FOUND',
    },
  ],
  [
    794,
    {
      message: 'error_UPDATE_METADATA_ENTRYPOINT_IN_VAULT_CONTRACT_NOT_FOUND',
      description: 'UPDATE METADATA ENTRYPOINT IN VAULT CONTRACT NOT FOUND',
    },
  ],
  [
    795,
    {
      message: 'error_DELEGATE_TEZ_TO_BAKER_ENTRYPOINT_IN_VAULT_CONTRACT_NOT_FOUND',
      description: 'DELEGATE TEZ TO BAKER ENTRYPOINT IN VAULT CONTRACT NOT FOUND',
    },
  ],
  [
    796,
    {
      message: 'error_VAULT_DELEGATE_MVK_TO_SATELLITE_ENTRYPOINT_IN_VAULT_CONTRACT_NOT_FOUND',
      description: 'VAULT DELEGATE MVK TO SATELLITE ENTRYPOINT IN VAULT CONTRACT NOT FOUND',
    },
  ],
  [
    797,
    {
      message: 'error_VAULT_DEPOSIT_ENTRYPOINT_IN_VAULT_CONTRACT_NOT_FOUND',
      description: 'VAULT DEPOSIT ENTRYPOINT IN VAULT CONTRACT NOT FOUND',
    },
  ],
  [
    798,
    {
      message: 'error_WITHDRAW_ENTRYPOINT_IN_VAULT_CONTRACT_NOT_FOUND',
      description: 'WITHDRAW ENTRYPOINT IN VAULT CONTRACT NOT FOUND',
    },
  ],
  [
    799,
    {
      message: 'error_ON_LIQUIDATE_ENTRYPOINT_IN_VAULT_CONTRACT_NOT_FOUND',
      description: 'ON LIQUIDATE ENTRYPOINT IN VAULT CONTRACT NOT FOUND',
    },
  ],
  [
    800,
    {
      message: 'error_VAULT_UPDATE_DEPOSITOR_ENTRYPOINT_IN_VAULT_CONTRACT_NOT_FOUND',
      description: 'VAULT UPDATE DEPOSITOR ENTRYPOINT IN VAULT CONTRACT NOT FOUND',
    },
  ],
  [
    801,
    {
      message: 'error_SET_LAMBDA_ENTRYPOINT_IN_VAULT_CONTRACT_NOT_FOUND',
      description: 'SET LAMBDA ENTRYPOINT IN VAULT CONTRACT NOT FOUND',
    },
  ],
  [
    802,
    {
      message: 'error_INIT_VAULT_ACTION_ENTRYPOINT_IN_VAULT_CONTRACT_NOT_FOUND',
      description: 'INIT VAULT ACTION ENTRYPOINT IN VAULT CONTRACT NOT FOUND',
    },
  ],
  [
    803,
    {
      message: 'error_CANNOT_DEPOSIT_PROTECTED_COLLATERAL_TOKEN',
      description: 'CANNOT DEPOSIT PROTECTED COLLATERAL TOKEN',
    },
  ],
  [
    804,
    {
      message: 'error_CANNOT_WITHDRAW_PROTECTED_COLLATERAL_TOKEN',
      description: 'CANNOT WITHDRAW PROTECTED COLLATERAL TOKEN',
    },
  ],
  [805, { message: 'error_VAULT_FACTORY_CONTRACT_NOT_FOUND', description: 'VAULT FACTORY CONTRACT NOT FOUND' }],
  [806, { message: 'error_ONLY_VAULT_FACTORY_CONTRACT_ALLOWED', description: 'ONLY VAULT FACTORY CONTRACT ALLOWED' }],
  [
    807,
    {
      message: 'error_CREATE_VAULT_ENTRYPOINT_IN_VAULT_FACTORY_CONTRACT_PAUSED',
      description: 'CREATE VAULT ENTRYPOINT IN VAULT FACTORY CONTRACT PAUSED',
    },
  ],
  [
    808,
    {
      message: 'error_CREATE_VAULT_ENTRYPOINT_IN_VAULT_FACTORY_CONTRACT_NOT_FOUND',
      description: 'CREATE VAULT ENTRYPOINT IN VAULT FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    809,
    {
      message: 'error_VAULT_LAMBDA_OPT_NOT_FOUND_IN_VAULT_FACTORY',
      description: 'VAULT LAMBDA OPT NOT FOUND IN VAULT FACTORY',
    },
  ],
  [
    810,
    {
      message: 'error_GET_GOVERNANCE_ADDRESS_VIEW_NOT_FOUND_IN_VAULT_FACTORY',
      description: 'GET GOVERNANCE ADDRESS VIEW NOT FOUND IN VAULT FACTORY',
    },
  ],
  [
    811,
    {
      message: 'error_GET_CONFIG_VIEW_IN_VAULT_FACTORY_CONTRACT_NOT_FOUND',
      description: 'GET CONFIG VIEW IN VAULT FACTORY CONTRACT NOT FOUND',
    },
  ],
  [
    812,
    {
      message: 'error_LENDING_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'LENDING CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [
    813,
    {
      message: 'error_ONLY_LENDING_CONTROLLER_CONTRACT_ALLOWED',
      description: 'ONLY LENDING CONTROLLER CONTRACT ALLOWED',
    },
  ],
  [814, { message: 'error_VAULT_ALREADY_EXISTS', description: 'VAULT ALREADY EXISTS' }],
  [815, { message: 'error_VAULT_CONTRACT_NOT_FOUND', description: 'VAULT CONTRACT NOT FOUND' }],
  [816, { message: 'error_VAULT_ID_ALREADY_USED', description: 'VAULT ID ALREADY USED' }],
  [817, { message: 'error_VAULT_IS_UNDERCOLLATERIZED', description: 'VAULT IS UNDERCOLLATERIZED' }],
  [
    818,
    {
      message: 'error_TOTAL_SERVICE_LOAN_FEE_CANNOT_BE_GREATER_THAN_BORROWED_AMOUNT',
      description: 'TOTAL SERVICE LOAN FEE CANNOT BE GREATER THAN BORROWED AMOUNT',
    },
  ],
  [
    819,
    {
      message: 'error_LOAN_FEE_CANNOT_BE_GREATER_THAN_BORROWED_AMOUNT',
      description: 'LOAN FEE CANNOT BE GREATER THAN BORROWED AMOUNT',
    },
  ],
  [
    820,
    {
      message: 'error_BORROW_CALLBACK_ENTRYPOINT_IN_TOKEN_POOL_CONTRACT_NOT_FOUND',
      description: 'BORROW CALLBACK ENTRYPOINT IN TOKEN POOL CONTRACT NOT FOUND',
    },
  ],
  [
    821,
    {
      message: 'error_REPAY_CALLBACK_ENTRYPOINT_IN_TOKEN_POOL_CONTRACT_NOT_FOUND',
      description: 'REPAY CALLBACK ENTRYPOINT IN TOKEN POOL CONTRACT NOT FOUND',
    },
  ],
  [822, { message: 'error_LOAN_OUTSTANDING_MISCALCULATION', description: 'LOAN OUTSTANDING MISCALCULATION' }],
  [823, { message: 'error_PRINCIPAL_REDUCTION_MISCALCULATION', description: 'PRINCIPAL REDUCTION MISCALCULATION' }],
  [824, { message: 'error_LOAN_INTEREST_MISCALCULATION', description: 'LOAN INTEREST MISCALCULATION' }],
  [825, { message: 'error_LOAN_OUTSTANDING_IS_NOT_ZERO', description: 'LOAN OUTSTANDING IS NOT ZERO' }],
  [
    826,
    {
      message: 'error_TRANSFER_ENTRYPOINT_IN_TOKEN_POOL_REWARD_CONTRACT_NOT_FOUND',
      description: 'TRANSFER ENTRYPOINT IN TOKEN POOL REWARD CONTRACT NOT FOUND',
    },
  ],
  [
    827,
    {
      message: 'error_STAKING_CONTRACT_ADDRESS_FOR_STAKED_TOKEN_NOT_FOUND',
      description: 'STAKING CONTRACT ADDRESS FOR STAKED TOKEN NOT FOUND',
    },
  ],
  [828, { message: 'error_INCORRECT_LOAN_TOKEN_AMOUNT_SENT', description: 'INCORRECT LOAN TOKEN AMOUNT SENT' }],
  [
    829,
    {
      message: 'error_INCORRECT_COLLATERAL_TOKEN_AMOUNT_SENT',
      description: 'INCORRECT COLLATERAL TOKEN AMOUNT SENT',
    },
  ],
  [
    830,
    {
      message: 'error_ONLY_VAULT_OR_VAULT_FACTORY_CONTRACT_ALLOWED',
      description: 'ONLY VAULT OR VAULT FACTORY CONTRACT ALLOWED',
    },
  ],
  [831, { message: 'error_LOAN_TOKEN_IS_PAUSED', description: 'LOAN TOKEN IS PAUSED' }],
  [832, { message: 'error_COLLATERAL_TOKEN_IS_PAUSED', description: 'COLLATERAL TOKEN IS PAUSED' }],
  [
    833,
    {
      message: 'error_SET_LOAN_TOKEN_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'SET LOAN TOKEN ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    834,
    {
      message: 'error_ADD_LIQUIDITY_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'ADD LIQUIDITY ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    835,
    {
      message: 'error_REMOVE_LIQUIDITY_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'REMOVE LIQUIDITY ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    836,
    {
      message: 'error_SET_COLLATERAL_TOKEN_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'SET COLLATERAL TOKEN ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    837,
    {
      message: 'error_REGISTER_VAULT_CREATION_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'REGISTER VAULT CREATION ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    838,
    {
      message: 'error_CLOSE_VAULT_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'CLOSE VAULT ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    839,
    {
      message: 'error_REGISTER_DEPOSIT_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'REGISTER DEPOSIT ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    840,
    {
      message: 'error_REGISTER_WITHDRAWAL_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'REGISTER WITHDRAWAL ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    841,
    {
      message: 'error_MARK_FOR_LIQUIDATION_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'MARK FOR LIQUIDATION ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    842,
    {
      message: 'error_LIQUIDATE_VAULT_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'LIQUIDATE VAULT ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    843,
    {
      message: 'error_BORROW_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'BORROW ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    844,
    {
      message: 'error_REPAY_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'REPAY ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    845,
    {
      message: 'error_VAULT_DEPOSIT_STAKED_TOKEN_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'VAULT DEPOSIT STAKED TOKEN ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    846,
    {
      message: 'error_VAULT_WITHDRAW_STAKED_TOKEN_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'VAULT WITHDRAW STAKED TOKEN ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    847,
    {
      message: 'error_VAULT_DELEGATE_TEZ_TO_BAKER_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'VAULT DELEGATE TEZ TO BAKER IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    848,
    {
      message: 'error_VAULT_DELEGATE_MVK_TO_SAT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'VAULT DELEGATE MVK TO SAT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    849,
    {
      message: 'error_VAULT_DEPOSIT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'VAULT DEPOSIT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    850,
    {
      message: 'error_VAULT_WITHDRAW_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'VAULT WITHDRAW IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    851,
    {
      message: 'error_VAULT_ON_LIQUIDATE_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'VAULT ON LIQUIDATE IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    852,
    {
      message: 'error_VAULT_UPDATE_DEPOSITOR_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'VAULT UPDATE DEPOSITOR IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    853,
    {
      message: 'error_CLAIM_REWARDS_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_PAUSED',
      description: 'CLAIM REWARDS ENTRYPOINT IN LENDING CONTROLLER CONTRACT PAUSED',
    },
  ],
  [
    854,
    {
      message: 'error_ON_VAULT_DEPOSIT_STAKE_ENTRYPOINT_IN_STAKING_CONTRACT_NOT_FOUND',
      description: 'ON VAULT DEPOSIT STAKE ENTRYPOINT IN STAKING CONTRACT NOT FOUND',
    },
  ],
  [
    855,
    {
      message: 'error_ON_VAULT_WITHDRAW_STAKE_ENTRYPOINT_IN_STAKING_CONTRACT_NOT_FOUND',
      description: 'ON VAULT WITHDRAW STAKE ENTRYPOINT IN STAKING CONTRACT NOT FOUND',
    },
  ],
  [
    856,
    {
      message: 'error_ON_VAULT_LIQUIDATE_STAKE_ENTRYPOINT_IN_STAKING_CONTRACT_NOT_FOUND',
      description: 'ON VAULT LIQUIDATE STAKE ENTRYPOINT IN STAKING CONTRACT NOT FOUND',
    },
  ],
  [
    857,
    {
      message: 'error_REGISTER_DEPOSIT_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'REGISTER DEPOSIT ENTRYPOINT IN LENDING CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [
    858,
    {
      message: 'error_REGISTER_WITHDRAWAL_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'REGISTER WITHDRAWAL ENTRYPOINT IN LENDING CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [
    859,
    {
      message: 'error_REGISTER_VAULT_CREATION_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'REGISTER VAULT CREATION ENTRYPOINT IN LENDING CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [
    860,
    {
      message: 'error_VAULT_LIQUIDATE_STAKED_MVK_IN_LENDING_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'VAULT LIQUIDATE STAKED MVN IN LENDING CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [
    861,
    {
      message: 'error_SET_LOAN_TOKEN_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'SET LOAN TOKEN ENTRYPOINT IN LENDING CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [
    862,
    {
      message: 'error_SET_COLLATERAL_TOKEN_ENTRYPOINT_IN_LENDING_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'SET COLLATERAL TOKEN ENTRYPOINT IN LENDING CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [
    863,
    {
      message: 'error_GET_VAULT_OPT_VIEW_IN_LENDING_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'GET VAULT OPT VIEW IN LENDING CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [
    864,
    {
      message: 'error_MINIMUM_LOAN_FEE_TREASURY_SHARE_CANNOT_BE_GREATER_THAN_MINIMUM_LOAN_FEE',
      description: 'MINIMUM LOAN FEE TREASURY SHARE CANNOT BE GREATER THAN MINIMUM LOAN FEE',
    },
  ],
  [
    865,
    {
      message: 'error_INTEREST_TREASURY_SHARE_CANNOT_BE_GREATER_THAN_INTEREST_ACCRUED',
      description: 'INTEREST TREASURY SHARE CANNOT BE GREATER THAN INTEREST ACCRUED',
    },
  ],
  [
    866,
    {
      message: 'error_INTEREST_TREASURY_SHARE_CANNOT_BE_GREATER_THAN_TOTAL_INTEREST_PAID',
      description: 'INTEREST TREASURY SHARE CANNOT BE GREATER THAN TOTAL INTEREST PAID',
    },
  ],
  [867, { message: 'error_LOAN_TOKEN_ALREADY_EXISTS', description: 'LOAN TOKEN ALREADY EXISTS' }],
  [868, { message: 'error_LOAN_TOKEN_RECORD_NOT_FOUND', description: 'LOAN TOKEN RECORD NOT FOUND' }],
  [869, { message: 'error_LOAN_TOKEN_LEDGER_NOT_FOUND', description: 'LOAN TOKEN LEDGER NOT FOUND' }],
  [870, { message: 'error_LOAN_TOKEN_RECORD_ALREADY_EXISTS', description: 'LOAN TOKEN RECORD ALREADY EXISTS' }],
  [871, { message: 'error_COLLATERAL_TOKEN_RECORD_NOT_FOUND', description: 'COLLATERAL TOKEN RECORD NOT FOUND' }],
  [872, { message: 'error_COLLATERAL_TOKEN_ALREADY_EXISTS', description: 'COLLATERAL TOKEN ALREADY EXISTS' }],
  [873, { message: 'error_SENDER_MUST_BE_VAULT_ADDRESS', description: 'SENDER MUST BE VAULT ADDRESS' }],
  [
    874,
    {
      message: 'error_INSUFFICIENT_COLLATERAL_TOKEN_BALANCE_IN_VAULT',
      description: 'INSUFFICIENT COLLATERAL TOKEN BALANCE IN VAULT',
    },
  ],
  [
    875,
    {
      message: 'error_CANNOT_WITHDRAW_MORE_THAN_TOTAL_COLLATERAL_BALANCE',
      description: 'CANNOT WITHDRAW MORE THAN TOTAL COLLATERAL BALANCE',
    },
  ],
  [
    876,
    {
      message: 'error_CANNOT_WITHDRAW_AS_VAULT_IS_UNDERCOLLATERIZED',
      description: 'CANNOT WITHDRAW AS VAULT IS UNDERCOLLATERIZED',
    },
  ],
  [877, { message: 'error_VAULT_IS_NOT_UNDERCOLLATERIZED', description: 'VAULT IS NOT UNDERCOLLATERIZED' }],
  [878, { message: 'error_VAULT_IS_NOT_LIQUIDATABLE', description: 'VAULT IS NOT LIQUIDATABLE' }],
  [
    879,
    {
      message: 'error_VAULT_HAS_ALREADY_BEEN_MARKED_FOR_LIQUIDATION',
      description: 'VAULT HAS ALREADY BEEN MARKED FOR LIQUIDATION',
    },
  ],
  [880, { message: 'error_VAULT_IS_NOT_READY_TO_BE_LIQUIDATED', description: 'VAULT IS NOT READY TO BE LIQUIDATED' }],
  [881, { message: 'error_OWNER_VAULT_SET_DOES_NOT_EXIST', description: 'OWNER VAULT SET DOES NOT EXIST' }],
  [
    882,
    {
      message: 'error_GET_COL_TOKEN_RECORD_BY_ADDRESS_OPT_VIEW_NOT_FOUND',
      description: 'GET COL TOKEN RECORD BY ADDRESS OPT VIEW NOT FOUND',
    },
  ],
  [
    883,
    {
      message: 'error_GET_COL_TOKEN_RECORD_BY_NAME_OPT_VIEW_NOT_FOUND',
      description: 'GET COL TOKEN RECORD BY NAME OPT VIEW NOT FOUND',
    },
  ],
  [
    884,
    {
      message: 'error_CANNOT_BURN_MORE_THAN_TOTAL_AMOUNT_OF_LP_TOKENS',
      description: 'CANNOT BURN MORE THAN TOTAL AMOUNT OF LP TOKENS',
    },
  ],
  [885, { message: 'error_TOKEN_POOL_TOTAL_CANNOT_BE_NEGATIVE', description: 'TOKEN POOL TOTAL CANNOT BE NEGATIVE' }],
  [
    886,
    {
      message: 'error_TOKEN_POOL_REMAINING_CANNOT_BE_NEGATIVE',
      description: 'TOKEN POOL REMAINING CANNOT BE NEGATIVE',
    },
  ],
  [
    887,
    {
      message: 'error_TEZOS_SENT_IS_NOT_EQUAL_TO_WITHDRAW_AMOUNT',
      description: 'TEZOS SENT IS NOT EQUAL TO WITHDRAW AMOUNT',
    },
  ],
  [
    888,
    {
      message: 'error_CANNOT_LIQUIDATE_MORE_THAN_TOTAL_COLLATERAL_BALANCE',
      description: 'CANNOT LIQUIDATE MORE THAN TOTAL COLLATERAL BALANCE',
    },
  ],
  [
    889,
    {
      message: 'error_TOO_MANY_DECIMAL_PLACES_FOR_CALCULATION',
      description: 'TOO MANY DECIMAL PLACES FOR CALCULATION',
    },
  ],
  [890, { message: 'error_REBASE_DECIMALS_OUT_OF_BOUNDS', description: 'REBASE DECIMALS OUT OF BOUNDS' }],
  [
    891,
    {
      message: 'error_CANNOT_LIQUIDATE_MORE_THAN_VAULT_LOAN_OUTSTANDING_TOTAL',
      description: 'CANNOT LIQUIDATE MORE THAN VAULT LOAN OUTSTANDING TOTAL',
    },
  ],
  [
    892,
    {
      message: 'error_CANNOT_LIQUIDATE_MORE_THAN_TOKEN_COLLATERAL_BALANCE',
      description: 'CANNOT LIQUIDATE MORE THAN TOKEN COLLATERAL BALANCE',
    },
  ],
  [
    893,
    {
      message: 'error_INITIAL_LOAN_PRINCIPAL_TOTAL_CANNOT_BE_GREATER_THAN_LOAN_OUTSTANDING_TOTAL',
      description: 'INITIAL LOAN PRINCIPAL TOTAL CANNOT BE GREATER THAN LOAN OUTSTANDING TOTAL',
    },
  ],
  [
    894,
    {
      message: 'error_CANNOT_REMOVE_MORE_LIQUIDITY_THAN_BALANCE',
      description: 'CANNOT REMOVE MORE LIQUIDITY THAN BALANCE',
    },
  ],
  [
    895,
    {
      message: 'error_MINT_OR_BURN_ENTRYPOINT_IN_M_TOKEN_NOT_FOUND',
      description: 'MINT OR BURN ENTRYPOINT IN M TOKEN NOT FOUND',
    },
  ],
  [
    896,
    {
      message: 'error_BREAK_GLASS_CONFIG_NOT_FOUND_IN_LENDING_CONTROLLER',
      description: 'BREAK GLASS CONFIG NOT FOUND IN LENDING CONTROLLER',
    },
  ],
  [
    897,
    {
      message: 'error_GET_LOAN_TOKEN_RECORD_OPT_VIEW_IN_LENDING_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'GET LOAN TOKEN RECORD OPT VIEW IN LENDING CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [
    898,
    {
      message: 'error_GET_TOKEN_POOL_DEPOSITOR_BALANCE_OPT_VIEW_IN_LENDING_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'GET TOKEN POOL DEPOSITOR BALANCE OPT VIEW IN LENDING CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [
    899,
    {
      message: 'error_GET_LOAN_TOKEN_LEDGER_VIEW_IN_LENDING_CONTROLLER_CONTRACT_NOT_FOUND',
      description: 'GET LOAN TOKEN LEDGER VIEW IN LENDING CONTROLLER CONTRACT NOT FOUND',
    },
  ],
  [900, { message: 'error_MIN_REPAYMENT_AMOUNT_NOT_REACHED', description: 'MIN REPAYMENT AMOUNT NOT REACHED' }],
  [
    901,
    {
      message: 'error_CANNOT_REGISTER_DEPOSIT_FOR_PROTECTED_COLLATERAL_TOKEN',
      description: 'CANNOT REGISTER DEPOSIT FOR PROTECTED COLLATERAL TOKEN',
    },
  ],
  [
    902,
    {
      message: 'error_CANNOT_REGISTER_WITHDRAWAL_FOR_PROTECTED_COLLATERAL_TOKEN',
      description: 'CANNOT REGISTER WITHDRAWAL FOR PROTECTED COLLATERAL TOKEN',
    },
  ],
  [
    903,
    {
      message: 'error_VAULT_NEEDS_TO_BE_MARKED_FOR_LIQUIDATION_AGAIN',
      description: 'VAULT NEEDS TO BE MARKED FOR LIQUIDATION AGAIN',
    },
  ],
  [
    904,
    {
      message: 'error_GET_STAKED_BALANCE_VIEW_IN_CONTRACT_NOT_FOUND',
      description: 'GET STAKED BALANCE VIEW IN CONTRACT NOT FOUND',
    },
  ],
  [905, { message: 'error_NOT_STAKED_TOKEN', description: 'NOT STAKED TOKEN' }],
  [
    906,
    {
      message: 'error_UPDATE_OPERATORS_ENTRYPOINT_IN_STAKING_TOKEN_CONTRACT_NOT_FOUND',
      description: 'UPDATE OPERATORS ENTRYPOINT IN STAKING TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    907,
    {
      message: 'error_MAX_DEPOSIT_AMOUNT_FOR_COLLATERAL_TOKEN_EXCEEDED',
      description: 'MAX DEPOSIT AMOUNT FOR COLLATERAL TOKEN EXCEEDED',
    },
  ],
  [
    908,
    {
      message: 'error_SET_ADMIN_ENTRYPOINT_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'SET ADMIN ENTRYPOINT IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    909,
    {
      message: 'error_SET_GOVERNANCE_ENTRYPOINT_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'SET GOVERNANCE ENTRYPOINT IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    910,
    {
      message: 'error_UPDATE_WHITELIST_CONTRACTS_ENTRYPOINT_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'UPDATE WHITELIST CONTRACTS ENTRYPOINT IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    911,
    {
      message: 'error_MISTAKEN_TRANSFER_ENTRYPOINT_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'MISTAKEN TRANSFER ENTRYPOINT IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    912,
    {
      message: 'error_TRANSFER_ENTRYPOINT_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'TRANSFER ENTRYPOINT IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    913,
    {
      message: 'error_BALANCE_OF_ENTRYPOINT_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'BALANCE OF ENTRYPOINT IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    914,
    {
      message: 'error_UPDATE_OPERATORS_ENTRYPOINT_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'UPDATE OPERATORS ENTRYPOINT IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    915,
    {
      message: 'error_ASSERT_METADATA_ENTRYPOINT_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'ASSERT METADATA ENTRYPOINT IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    916,
    {
      message: 'error_MINT_OR_BURN_ENTRYPOINT_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'MINT OR BURN ENTRYPOINT IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    917,
    {
      message: 'error_GET_ADMIN_VIEW_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET ADMIN VIEW IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    918,
    {
      message: 'error_GET_WHITELIST_CONTRACTS_VIEW_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET WHITELIST CONTRACTS VIEW IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    919,
    {
      message: 'error_GET_OPERATOR_OPT_VIEW_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET OPERATOR OPT VIEW IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    920,
    {
      message: 'error_GET_BALANCE_VIEW_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET BALANCE VIEW IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    921,
    {
      message: 'error_GET_REWARD_INDEX_VIEW_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'GET REWARD INDEX VIEW IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    922,
    {
      message: 'error_ALL_TOKENS_VIEW_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'ALL TOKENS VIEW IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    923,
    {
      message: 'error_IS_OPERATOR_VIEW_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'IS OPERATOR VIEW IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
  [
    924,
    {
      message: 'error_TOKEN_METADATA_VIEW_IN_M_TOKEN_CONTRACT_NOT_FOUND',
      description: 'TOKEN METADATA VIEW IN M TOKEN CONTRACT NOT FOUND',
    },
  ],
])
