import { calcExitFee, calcMLI } from '../../../utils/calcFunctions'

// components
import { CommaNumber } from 'app/App.components/CommaNumber/CommaNumber.controller'
import { TzAddress } from '../../../app/App.components/TzAddress/TzAddress.view'
import { DoormanList, DoormanStatsHeader, DoormanStatsStyled } from './DoormanStats.style'
import { Tooltip } from 'app/App.components/Tooltip/Tooltip'
import Icon from 'app/App.components/Icon/Icon.view'
import CustomLink from 'app/App.components/CustomLink/CustomLink'

// helpers
import { PRIMARY_TZ_ADDRESS_COLOR } from 'app/App.components/TzAddress/TzAddress.constants'

type DoormanStatsPropsType = {
  mvnExchangeRate: number
  maximumTotalSupply: number
  totalStakedMvn: number
  totalSupply: number
  doormanAddress: string | null
  mvnTokenAddress: string | null
}

export const DoormanStats = ({
  mvnExchangeRate,
  maximumTotalSupply,
  totalStakedMvn,
  totalSupply,
  doormanAddress,
  mvnTokenAddress,
}: DoormanStatsPropsType) => {
  const mli = calcMLI(totalSupply, totalStakedMvn)
  const fee = calcExitFee(totalSupply, totalStakedMvn)
  const marketCapValue = mvnExchangeRate ? mvnExchangeRate * totalSupply : 0

  return (
    <DoormanStatsStyled>
      <DoormanStatsHeader>Key MVN Metrics</DoormanStatsHeader>
      <DoormanList>
        <div>
          <h4>
            MVN Price
            <Tooltip>
              <Tooltip.Trigger className="ml-5">
                <Icon id="info" />
              </Tooltip.Trigger>
              <Tooltip.Content>
                Once launched, the price will be taken from the exchange MVN is listed on, not from our Oracle price
                feeds.
              </Tooltip.Content>
            </Tooltip>
          </h4>
          <var>
            <CommaNumber value={mvnExchangeRate} beginningText={'$'} />
          </var>
        </div>

        <div>
          <h4>MVN Token Address</h4>
          <var className="click-address">
            <TzAddress type={PRIMARY_TZ_ADDRESS_COLOR} tzAddress={mvnTokenAddress} hasIcon />
          </var>
        </div>

        <div>
          <h4>
            Doorman Address
            <Tooltip>
              <Tooltip.Trigger className="ml-5">
                <Icon id="info" />
              </Tooltip.Trigger>
              <Tooltip.Content>
                <>
                  The Doorman contract controls the staking mechanism for MVN. Handles all actions connected to it and
                  interacts with the other relevant contracts.{' '}
                  <a
                    href="https://docs.mavenfinance.io/smart-contracts/smart-contracts-overview/doorman-contract"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read more.
                  </a>
                </>
              </Tooltip.Content>
            </Tooltip>
          </h4>
          <var className="click-address">
            <TzAddress type={PRIMARY_TZ_ADDRESS_COLOR} tzAddress={doormanAddress} hasIcon />
          </var>
        </div>

        <div>
          <h4>
            MVN Loyalty Index
            <CustomLink to="https://docs.mavenfinance.io/maven-finance/staking/benefits-and-fees-of-staking">
              <Tooltip>
                <Tooltip.Trigger>
                  <Icon id="info" />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  The Maven Loyalty Index is a metric that balances MVN & sMVN. The more MVN is staked v.s. MVN, the
                  higher the MLI, and the lower the exit fee is. The less MVN staked v.s. MVN, the lower the MLI, and
                  the exit fee will rise. Click to read more.
                </Tooltip.Content>
              </Tooltip>
            </CustomLink>
          </h4>
          <var>
            <CommaNumber value={mli} />
          </var>
        </div>

        <div>
          <h4>
            Exit Fee
            <CustomLink to="https://docs.mavenfinance.io/maven-finance/staking/benefits-and-fees-of-staking#exit-fee">
              <Tooltip>
                <Tooltip.Trigger>
                  <Icon id="info" />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  The Exit Fee is dynamic, adjusts according to the MLI, and may modified by governance vote. Exit fees
                  are paid directly to sMVN stakeholders for remaining active participants in securing the network.
                  Click to read more.
                </Tooltip.Content>
              </Tooltip>
            </CustomLink>
          </h4>
          <var>
            <CommaNumber value={fee} endingText={'%'} />
          </var>
        </div>

        <div>
          <h4>Total Staked MVN</h4>
          <var>
            <CommaNumber value={totalStakedMvn} endingText={'MVN'} />
          </var>
        </div>

        <div>
          <h4>Total Circulating</h4>
          <var>
            <CommaNumber value={totalSupply} endingText={'MVN'} />
          </var>
        </div>

        <div>
          <h4>Max Supply</h4>
          <var>
            <CommaNumber value={maximumTotalSupply} endingText={'MVN'} />
          </var>
        </div>

        <div>
          <h4>Market Cap</h4>
          <var>
            <CommaNumber value={marketCapValue} endingText={'USD'} />
          </var>
        </div>

        {/* <div>
          <h4>Total supply</h4>
          <var>
            <CommaNumber value={maximumTotalSupply}  endingText={'MVN'} />
          </var>
        </div> */}
      </DoormanList>
    </DoormanStatsStyled>
  )
}
