import styled from 'styled-components'

export const ProposalSubmissionBannerStyled = styled.div`
  margin-bottom: 10px;
`

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-block: 16px;
`
