import Icon from 'app/App.components/Icon/Icon.view'
import TimeRemaining from '../TimeRemaining/TimeRemaining.controller'
import { GovernanceTopBarStyled, GovTopBarPhaseText } from './GovernanceTopBar.style'

import { GovPhases } from 'providers/ProposalsProvider/helpers/proposals.const'

import { GovernancePhaseType } from 'providers/ProposalsProvider/helpers/proposals.types'

export type GovernanceTopBarProps = {
  governancePhase: GovernancePhaseType
}
export const GovernanceTopBar = ({ governancePhase }: GovernanceTopBarProps) => {
  return (
    <GovernanceTopBarStyled>
      <GovTopBarPhaseText isActivePhase={governancePhase === GovPhases.PROPOSAL}>Proposal</GovTopBarPhaseText>

      <Icon id="greater-than" />
      <GovTopBarPhaseText isActivePhase={governancePhase === GovPhases.VOTING}>Voting</GovTopBarPhaseText>

      <Icon id="greater-than" />
      <GovTopBarPhaseText isActivePhase={governancePhase === GovPhases.TIMELOCK}>Time Lock</GovTopBarPhaseText>

      <Icon id="greater-than" />
      <GovTopBarPhaseText isActivePhase={governancePhase === GovPhases.EXECUTION}>Execution</GovTopBarPhaseText>

      <div className="action">
        <TimeRemaining />
      </div>
    </GovernanceTopBarStyled>
  )
}
