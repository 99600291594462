import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

// context, hooks
import { useSatelliteStatuses } from 'providers/SatellitesProvider/hooks/useSatelliteStatus'
import { useUserContext } from 'providers/UserProvider/user.provider'
import { useSatellitesContext } from 'providers/SatellitesProvider/satellites.provider'

// consts
import { SMVN_TOKEN_ADDRESS } from 'utils/constants'
import { STATUS_FLAG_DOWN, STATUS_FLAG_WARNING } from 'app/App.components/StatusFlag/StatusFlag.constants'
import { PRIMARY_TZ_ADDRESS_COLOR } from 'app/App.components/TzAddress/TzAddress.constants'
import {
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  BUTTON_SIMPLE,
  BUTTON_WIDE,
} from 'app/App.components/Button/Button.constants'
import { TOTAL_VOTING_POWER_TOOLTIP_TEXT } from 'texts/tooltips/satellite'
import {
  ACTIVE_SATELLITE_STATUS,
  BANNED_SATELLITE_STATUS,
  DELEGATE_ACTION,
  DISTRIBUTE_PROPOSALS_REWARDS_ACTION,
  SATELLITE_ORACLE_STATUSES,
  SATELLITE_STATUSES,
  SATELLITE_VOTES_MAPPER,
  UNDELEGATE_ACTION,
} from 'providers/SatellitesProvider/satellites.const'

// helpers
import {
  getSatelliteParticipation,
  getStatusColorBasedOnOracleType,
} from 'providers/SatellitesProvider/helpers/satellites.utils'
import { getUserTokenBalanceByAddress } from 'providers/UserProvider/helpers/userBalances.helpers'
import { distributeProposalRewards } from 'providers/UserProvider/actions/user.actions'
import { delegate, undelegate } from 'providers/SatellitesProvider/actions/satellites.actions'

// view
import { CommaNumber } from 'app/App.components/CommaNumber/CommaNumber.controller'
import { StatusFlag } from 'app/App.components/StatusFlag/StatusFlag.controller'
import { TzAddress } from 'app/App.components/TzAddress/TzAddress.view'
import Icon from 'app/App.components/Icon/Icon.view'
import Button from 'app/App.components/Button/NewButton'
import { Tooltip } from 'app/App.components/Tooltip/Tooltip'
import { ImageWithPlug } from 'app/App.components/Icon/ImageWithPlug'

// types
import { SatelliteRecordType } from 'providers/SatellitesProvider/satellites.provider.types'

//styles
import {
  SatelliteCard,
  SatelliteCardButtons,
  SatelliteCardInner,
  SatelliteCardRow,
  SatelliteMainText,
  SatelliteOracleStatusComponent,
  SatelliteProfileDetails,
  SatelliteProfileImageContainer,
  SatelliteSubText,
  SatelliteTextGroup,
} from './SatelliteCard.style'

// hooks
import { useDappConfigContext } from 'providers/DappConfigProvider/dappConfig.provider'
import { useToasterContext } from 'providers/ToasterProvider/toaster.provider'
import { HookContractActionArgs, useContractAction } from 'app/App.hooks/useContractAction'
import { useUserRewards } from 'providers/UserProvider/hooks/useUserRewards'

type SatelliteListItemProps = {
  satellite: SatelliteRecordType
  isDetailsPage?: boolean
  userHasSatelliteRewards?: boolean
  className?: string
  children?: JSX.Element
}

const SatelliteLastProposalVote = ({
  lastVotedProposal,
}: {
  lastVotedProposal: SatelliteRecordType['lastVotedProposal']
}) => {
  if (!lastVotedProposal)
    return (
      <SatelliteCardRow>
        <div>Has not voted this cycle</div>
      </SatelliteCardRow>
    )

  const { vote, proposalId, proposalTitle } = lastVotedProposal
  const voteText = SATELLITE_VOTES_MAPPER[vote]

  return (
    <SatelliteCardRow>
      <div>
        Voted <span className={`voting-${voteText.toLowerCase()}`}>{voteText.toUpperCase()}</span> on current Proposal{' '}
        {proposalId} – {proposalTitle}
      </div>
    </SatelliteCardRow>
  )
}

export const SatelliteListItem = ({ satellite, isDetailsPage = false, children }: SatelliteListItemProps) => {
  const { userTokensBalances, isSatellite: isUserSatellite, satelliteMvnIsDelegatedTo, userAddress } = useUserContext()
  const { availableProposalRewards } = useUserRewards()
  const { proposalsAmount, satelliteGovActionsAmount, finRequestsAmount } = useSatellitesContext()
  const {
    contractAddresses: { delegationAddress, mvnTokenAddress, governanceAddress },
    globalLoadingState: { isActionActive },
  } = useDappConfigContext()
  const { bug } = useToasterContext()

  const { oracleStatus, satelliteStatus } = useSatelliteStatuses(satellite)

  const { proposalParticipation, votingParticipation } = getSatelliteParticipation({
    satellite,
    proposalsAmount,
    satelliteGovActionsAmount,
    finRequestsAmount,
  })

  const {
    currentlyRegistered,
    sMvnBalance,
    delegationRatio,
    totalDelegatedAmount,
    address: satelliteAddress,
  } = satellite

  const freesMVNSpace = Math.max(sMvnBalance * delegationRatio - totalDelegatedAmount, 0)
  const isUserDelegatedToThisSatellite = satelliteAddress === satelliteMvnIsDelegatedTo
  const balanceOver1SMvn = getUserTokenBalanceByAddress({ userTokensBalances, tokenAddress: SMVN_TOKEN_ADDRESS }) >= 1
  const isSatelliteActive = satelliteStatus === ACTIVE_SATELLITE_STATUS && currentlyRegistered

  // Actions ---------------------------------------------------------

  // delegate action --------------
  const delegateAction = useCallback(async () => {
    if (!userAddress) {
      bug('Click Connect in the left menu', 'Please connect your wallet')
      return null
    }
    if (!delegationAddress) {
      bug('Wrong delegation address')
      return null
    }

    const mvnTokenBalance = getUserTokenBalanceByAddress({ userTokensBalances, tokenAddress: mvnTokenAddress })
    const sMvnTokenBalance = getUserTokenBalanceByAddress({ userTokensBalances, tokenAddress: SMVN_TOKEN_ADDRESS })

    if (mvnTokenBalance === 0) {
      bug('Unable to Delegate', 'Please buy MVN and stake it')
      return null
    }

    if (sMvnTokenBalance === 0) {
      bug('Unable to Delegate', 'Please stake your MVN')
      return null
    }

    return await delegate(userAddress, satelliteAddress, delegationAddress)
  }, [bug, delegationAddress, mvnTokenAddress, satelliteAddress, userAddress, userTokensBalances])

  const delegateContractActionProps: HookContractActionArgs = useMemo(
    () => ({
      actionType: DELEGATE_ACTION,
      actionFn: delegateAction,
    }),
    [delegateAction],
  )

  const { action: delegateCallback } = useContractAction(delegateContractActionProps)

  // undelegate action --------------
  const undelegateAction = useCallback(async () => {
    if (!userAddress) {
      bug('Click Connect in the left menu', 'Please connect your wallet')
      return null
    }

    if (!delegationAddress || !governanceAddress) {
      bug('Wrong contract address')
      return null
    }

    return await undelegate(
      userAddress,
      availableProposalRewards,
      satelliteAddress,
      delegationAddress,
      governanceAddress,
    )
  }, [availableProposalRewards, bug, delegationAddress, governanceAddress, satelliteAddress, userAddress])

  const unDelegateContractActionProps: HookContractActionArgs = useMemo(
    () => ({
      actionType: UNDELEGATE_ACTION,
      actionFn: undelegateAction,
    }),
    [undelegateAction],
  )

  const { action: undelegateCallback } = useContractAction(unDelegateContractActionProps)

  // distributeRewards action
  const distributeRewardsAction = useCallback(async () => {
    if (!userAddress) {
      bug('Click Connect in the left menu', 'Please connect your wallet')
      return null
    }

    if (!governanceAddress) {
      bug('Wrong governance address')
      return null
    }

    const satelliteAddressToDistribute = isUserSatellite ? userAddress : satelliteMvnIsDelegatedTo

    if (!satelliteAddressToDistribute) {
      bug('Wrong satellite address to distribute rewards')
      return null
    }

    return await distributeProposalRewards(governanceAddress, satelliteAddressToDistribute, availableProposalRewards)
  }, [userAddress, governanceAddress, isUserSatellite, satelliteMvnIsDelegatedTo, availableProposalRewards, bug])

  const distributeRewardsContractActionProps: HookContractActionArgs = useMemo(
    () => ({
      actionType: DISTRIBUTE_PROPOSALS_REWARDS_ACTION,
      actionFn: distributeRewardsAction,
    }),
    [distributeRewardsAction],
  )

  const { action: distributeRewardsCallback } = useContractAction(distributeRewardsContractActionProps)

  const hasEmptyRightSection = isUserSatellite || !isSatelliteActive
  return (
    <SatelliteCard key={String(`satellite${satellite.address}`)}>
      <SatelliteCardInner $isExtendedListItem={isDetailsPage} $hasEmptyRightSection={hasEmptyRightSection}>
        <div className="grid-container">
          <div className="grid-item">
            <SatelliteProfileImageContainer>
              <ImageWithPlug
                useRounded
                alt={satellite.name}
                imageLink={satellite.image}
                plugSrc="/images/default-user.png"
              />
            </SatelliteProfileImageContainer>

            <SatelliteTextGroup>
              <SatelliteMainText $hasEmptyRightSection={hasEmptyRightSection}>{satellite.name}</SatelliteMainText>
              <SatelliteSubText>
                <TzAddress tzAddress={satellite.address} type={PRIMARY_TZ_ADDRESS_COLOR} hasIcon isBold />
              </SatelliteSubText>
            </SatelliteTextGroup>
          </div>

          <div className="grid-item">
            <SatelliteTextGroup>
              <SatelliteMainText>Fee</SatelliteMainText>
              <SatelliteSubText>
                <CommaNumber value={satellite.satelliteFee} endingText="%" />
              </SatelliteSubText>
            </SatelliteTextGroup>
          </div>
          <div className="grid-item">
            <SatelliteTextGroup>
              <SatelliteMainText>Free sMVN Space</SatelliteMainText>
              <SatelliteSubText>
                <CommaNumber value={freesMVNSpace} />
              </SatelliteSubText>
            </SatelliteTextGroup>
          </div>

          <div className="grid-item grid-item-replaceable">
            {!isDetailsPage ? (
              <SatelliteProfileDetails>
                <Link to={`/satellites/satellite-details/${satellite.address}`}>
                  <Button kind={BUTTON_SIMPLE}>
                    <Icon id="man" className="icon" />
                    <span>Profile Details</span>
                  </Button>
                </Link>
              </SatelliteProfileDetails>
            ) : (
              <SatelliteTextGroup>
                <div className="text-wrapper">
                  <SatelliteMainText>Total Voting Power</SatelliteMainText>
                  <Tooltip>
                    <Tooltip.Trigger className="ml-3">
                      <Icon id="info" />
                    </Tooltip.Trigger>
                    <Tooltip.Content>{TOTAL_VOTING_POWER_TOOLTIP_TEXT}</Tooltip.Content>
                  </Tooltip>
                </div>
                <SatelliteSubText>
                  <CommaNumber value={satellite.totalVotingPower} endingText="sMVN" />
                </SatelliteSubText>
              </SatelliteTextGroup>
            )}
          </div>

          <div className="grid-item">
            <SatelliteTextGroup>
              <SatelliteMainText>Participation</SatelliteMainText>
              <SatelliteSubText>
                <CommaNumber value={(proposalParticipation + votingParticipation) / 2} endingText="%" />
              </SatelliteSubText>
            </SatelliteTextGroup>
          </div>
          <div className="grid-item">
            <SatelliteTextGroup className="oracle-status">
              <SatelliteMainText>Oracle Status</SatelliteMainText>
              <SatelliteSubText>
                <SatelliteOracleStatusComponent>
                  <StatusFlag
                    status={getStatusColorBasedOnOracleType(oracleStatus)}
                    text={SATELLITE_ORACLE_STATUSES[oracleStatus]}
                  />
                </SatelliteOracleStatusComponent>
              </SatelliteSubText>
            </SatelliteTextGroup>
          </div>
        </div>

        <SatelliteCardButtons>
          {satelliteStatus !== ACTIVE_SATELLITE_STATUS && (
            <div>
              <StatusFlag
                status={satelliteStatus !== BANNED_SATELLITE_STATUS ? STATUS_FLAG_DOWN : STATUS_FLAG_WARNING}
                text={SATELLITE_STATUSES[satelliteStatus]}
              />
            </div>
          )}

          {/* Satellite action for user */}
          <>
            {/**
             * Delegate and Undelegate buttons (only 1 of them)
             * show on of them is current user is not satellite, cuz satellite can't delegate only be delegated, also is current card is for inactive satellite
             * such type of satellites can't be delegated
             *
             * Delegate button if user is not delegated to satellite on card, but it's disabled if user don't have smvn to delegate
             *
             * Undelegate button shown if user is delegated to satellite on card
             */}
            {isUserSatellite || !isSatelliteActive ? null : isUserDelegatedToThisSatellite ? (
              <Button kind={BUTTON_SECONDARY} form={BUTTON_WIDE} onClick={undelegateCallback} disabled={isActionActive}>
                <Icon id="man-close" />
                Undelegate
              </Button>
            ) : (
              <Button
                kind={BUTTON_PRIMARY}
                form={BUTTON_WIDE}
                onClick={delegateCallback}
                disabled={isActionActive || !balanceOver1SMvn}
              >
                <Icon id="man-check" />
                Delegate
              </Button>
            )}

            {/**
             * Distribute Proposal Rewards show to regular user on card of satellite to who he has delegated, and satellite is active, and opened details page
             * button is active, when user have rewards from this satellite
             */}
            {!isUserSatellite && isUserDelegatedToThisSatellite && isSatelliteActive && isDetailsPage ? (
              <Button
                kind={BUTTON_PRIMARY}
                form={BUTTON_WIDE}
                onClick={distributeRewardsCallback}
                disabled={availableProposalRewards.length === 0}
              >
                <Icon id="loans" />
                Distribute Rewards
              </Button>
            ) : null}
          </>
        </SatelliteCardButtons>
      </SatelliteCardInner>

      {children ? children : <SatelliteLastProposalVote lastVotedProposal={satellite.lastVotedProposal} />}
    </SatelliteCard>
  )
}
