import React, { useEffect, useMemo, useState } from 'react'

// context
import { useProposalsContext } from 'providers/ProposalsProvider/proposals.provider'
import { useDappConfigContext } from 'providers/DappConfigProvider/dappConfig.provider'

// types
import { ProposalBytesType, StageTwoFormProps } from '../ProposalSubmission.types'

// components
import Icon from '../../../app/App.components/Icon/Icon.view'
import { Input } from '../../../app/App.components/Input/NewInput'
import { TextArea } from '../../../app/App.components/TextArea/TextArea.controller'
import Button from 'app/App.components/Button/NewButton'
import { ProposalSubmissionBanner } from '../ProposalSubmissionBanner/ProposalSubmissionBanner'
import { Info } from 'app/App.components/Info/Info.view'
import { Tooltip } from 'app/App.components/Tooltip/Tooltip'
import { CommaNumber } from 'app/App.components/CommaNumber/CommaNumber.controller'

// helpers
import { isHexadecimal } from 'utils/validatorFunctions'
import { getBytesPairValidationStatus } from '../helpers/proposalSubmissionValidation.utils'
import { checkBytesPairExists } from '../helpers/ProposalSubmissionDiff.utils'
import { containSpaces } from 'app/App.utils/input'

// const
import { STAGE_2_DESCRIPTION } from 'texts/tooltips/governance'
import { INPUT_MEDIUM, INPUT_STATUS_ERROR, INPUT_STATUS_SUCCESS } from 'app/App.components/Input/Input.constants'
import { INFO_DEFAULT, INFO_WARNING } from 'app/App.components/Info/info.constants'
import { BUTTON_SIMPLE, BUTTON_SIMPLE_SMALL } from 'app/App.components/Button/Button.constants'
import { PROPOSAL_BYTE } from '../helpers/proposalSubmission.const'
import { GovPhases } from 'providers/ProposalsProvider/helpers/proposals.const'

// styles
import { SubmitProposalBytes, SubmitProposalBytesPair, SubmitProposalGeneralData } from '../ProposalSubmission.style'

// valid bytes text for testing: 0502000000c703200743036e0a000000160136047207da50aa1f751393d670b8810457c21d43000655076504620000001525757064617465436f6e6669674e657756616c75650864046c0000001925636f6e6669675661756c744e616d654d61784c656e677468046c0000000625656d7074790000001325757064617465436f6e666967416374696f6e0000000d25757064617465436f6e666967072f0200000008074303620000032702000000000743036a0000034f0533036c0743036200140342034d053d036d034c031b
export const StageTwoForm = ({
  proposalId,
  currentProposal: { proposalData = [], title, locked },
  currentProposalValidation,
  isFormDisabled,
  updateLocalProposalValidation,
  updateLocalProposalData,
}: StageTwoFormProps) => {
  const {
    maxLengths: {
      governance: { proposalMetadataTitleMaxLength, proposalDescriptionMaxLength },
    },
  } = useDappConfigContext()
  const {
    config: { governancePhase, fee, successReward },
  } = useProposalsContext()

  const isProposalPeriod = governancePhase === GovPhases.PROPOSAL || governancePhase === GovPhases.EXECUTION

  // is no bytes pair on proposal change add empty pair on client
  useEffect(() => {
    if (!proposalData.some(checkBytesPairExists) && !locked) {
      handleCreateNewByte()
    }
  }, [proposalId, locked])

  const handleOnChange = (byte: ProposalBytesType, text: string, type: string) => {
    // update input value
    updateLocalProposalData(
      {
        proposalData: proposalData.map((oldByte) =>
          oldByte.id === byte.id
            ? {
                ...oldByte,
                [type]: text,
              }
            : oldByte,
        ),
      },
      proposalId,
    )

    // update validation for input
    switch (type) {
      case 'title':
        updateLocalProposalValidation(
          {
            bytesValidation: currentProposalValidation.bytesValidation.map((byteValidity) =>
              byteValidity.byteId === byte.id
                ? {
                    ...byteValidity,
                    validTitle: getBytesPairValidationStatus(text, proposalMetadataTitleMaxLength),
                  }
                : byteValidity,
            ),
          },
          proposalId,
        )
        break
      case 'encoded_code':
        updateLocalProposalValidation(
          {
            bytesValidation: currentProposalValidation.bytesValidation.map((byteValidity) =>
              byteValidity.byteId === byte.id
                ? {
                    ...byteValidity,
                    validBytes:
                      isHexadecimal(text) && getBytesPairValidationStatus(text) === INPUT_STATUS_SUCCESS
                        ? INPUT_STATUS_SUCCESS
                        : INPUT_STATUS_ERROR,
                  }
                : byteValidity,
            ),
          },
          proposalId,
        )
        break
      case 'code_description':
        updateLocalProposalValidation(
          {
            bytesValidation: currentProposalValidation.bytesValidation.map((byteValidity) =>
              byteValidity.byteId === byte.id
                ? {
                    ...byteValidity,
                    validDescr: getBytesPairValidationStatus(text, proposalDescriptionMaxLength),
                  }
                : byteValidity,
            ),
          },
          proposalId,
        )
        break
    }
  }

  function handleOnBlur<G extends HTMLInputElement | HTMLTextAreaElement>(
    byte: ProposalBytesType,
    e: React.FocusEvent<G>,
  ) {
    const { name, value } = e.target
    if (containSpaces(value)) {
      const trimmedValue = value.trim()
      updateLocalProposalData(
        {
          proposalData: proposalData.map((oldByte) =>
            oldByte.id === byte.id ? { ...oldByte, [name]: trimmedValue } : oldByte,
          ),
        },
        proposalId,
      )
    }
  }

  // adding new empty bytes pair
  const handleCreateNewByte = () => {
    const newId = Date.now()
    const newOrder = Math.max(...proposalData.map(({ order }) => order), 0) + 1
    // add bytes pair to actual proposal data to display it to user
    updateLocalProposalData(
      {
        proposalData: [
          ...proposalData,
          {
            ...PROPOSAL_BYTE,
            id: newId,
            order: newOrder,
          },
        ],
      },
      proposalId,
    )
    // add validation field for new bytes pair
    updateLocalProposalValidation(
      {
        bytesValidation: (currentProposalValidation.bytesValidation ?? []).concat({
          validBytes: '',
          validTitle: '',
          validDescr: '',
          byteId: newId,
        }),
      },
      proposalId,
    )
  }

  // removing bytes pair
  const handleDeletePair = (removeId: number) => {
    const pairToRemove = proposalData.find(({ id }) => removeId === id)

    if (pairToRemove) {
      updateLocalProposalData(
        {
          proposalData: proposalData.filter(({ id }) => id !== removeId),
        },
        proposalId,
      )
      updateLocalProposalValidation(
        {
          bytesValidation: currentProposalValidation.bytesValidation.filter(({ byteId }) => byteId !== removeId),
        },
        proposalId,
      )
    }
  }

  // Drag & drop variables and event handlers
  const [DnDSelectedProposal, setDnDSeletedProposal] = useState<ProposalBytesType | null>(null)
  const isDraggable = useMemo(() => proposalData?.length > 1, [proposalData])

  // handling changing order of elements on drop event
  const dropHandler = (e: React.DragEvent<HTMLElement>, byteToDrop: ProposalBytesType) => {
    e.preventDefault()
    if (DnDSelectedProposal) {
      // reordered and saved client bytes that user sees
      const updatedBytes = proposalData
        .map((byte) => {
          if (byte.id === byteToDrop.id) {
            return { ...byte, order: Number(DnDSelectedProposal?.order) }
          }

          if (byte.id === DnDSelectedProposal?.id) {
            return { ...byte, order: byteToDrop.order }
          }

          return byte
        })
        .sort((a, b) => a.order - b.order)

      updateLocalProposalData(
        {
          proposalData: updatedBytes,
        },
        proposalId,
      )
    }
  }

  // removing classNames for under grad event cards
  const dragRemoveStyling = () => {
    updateLocalProposalData(
      {
        proposalData: proposalData.map((byte) => ({
          ...byte,
          isUnderTheDrop: false,
        })),
      },
      proposalId,
    )
  }

  // selecting card to drag
  const dragStartHandler = (byte: ProposalBytesType) => {
    setDnDSeletedProposal(byte)
  }

  // adding class names to under drag cards
  const dragOverHandler = (e: React.DragEvent<HTMLElement>, bytePairId: number) => {
    e.preventDefault()
    updateLocalProposalData(
      {
        proposalData: proposalData.map((byte) => ({
          ...byte,
          ...(bytePairId === byte.id && byte.id !== DnDSelectedProposal?.id ? { isUnderTheDrop: true } : {}),
        })),
      },
      proposalId,
    )
  }

  return (
    <>
      <div className="stage-descr">{STAGE_2_DESCRIPTION}</div>

      <ProposalSubmissionBanner />

      <SubmitProposalGeneralData>
        <div className="submitted-data">
          <div className="label">1 - Proposal Title</div>
          <div className="value">{title || '–'}</div>
        </div>

        <div className="submitted-data">
          <div className="label">2 - Proposal Success Reward</div>
          <CommaNumber className="value" value={successReward} endingText="MVN" />
        </div>

        <div className="submitted-data">
          <div className="label">3 - Fee</div>
          <CommaNumber className="value" value={fee} endingText="MVRK" />
        </div>
      </SubmitProposalGeneralData>

      <div className="bytes-label label">4 - Enter Proposal Bytes</div>

      <Info
        type={INFO_DEFAULT}
        text={
          <>
            Bytes are executed in FILO. If you want to change the order of execution of the bytes, drag the pair to the
            desired position. Learn more on how to create bytes for governance proposals in the{' '}
            <a
              href="https://www.npmjs.com/package/@mavrykdynamics/create-lambda-bytes"
              target="_blank"
              rel="noreferrer"
            >
              Maven Finance Docs
            </a>
            .
          </>
        }
      />

      <SubmitProposalBytes>
        {proposalData.map((item, i) => {
          if (
            !checkBytesPairExists(item) ||
            !item ||
            typeof item.title !== 'string' ||
            typeof item.encoded_code !== 'string' ||
            typeof item.code_description !== 'string'
          )
            return null
          const { title, encoded_code, code_description } = item
          const existInServer = Boolean(proposalData?.find(({ id }) => item.id === id && !item.isLocalBytes))
          const validityObject = currentProposalValidation.bytesValidation?.find(({ byteId }) => byteId === item.id)

          return (
            <SubmitProposalBytesPair
              key={item.id}
              className={`${isDraggable ? 'draggabe' : ''} ${item.isUnderTheDrop ? 'underDrop' : ''}`}
              draggable={isDraggable}
              onDragLeave={dragRemoveStyling}
              onDragEnd={dragRemoveStyling}
              onDragStart={() => dragStartHandler(item)}
              onDragOver={(e) => dragOverHandler(e, item.id)}
              onDrop={(e) => dropHandler(e, item)}
            >
              <div className="idx">{i + 1}</div>

              <Input
                settings={{
                  label: 'Enter Proposal Bytes Title',
                  inputStatus: validityObject?.validTitle,
                  inputSize: INPUT_MEDIUM,
                }}
                inputProps={{
                  disabled: existInServer || locked || !isProposalPeriod || isFormDisabled,
                  value: title,
                  type: 'text',
                  name: 'title',
                  onBlur: (e: React.FocusEvent<HTMLInputElement>) => handleOnBlur(item, e),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChange(item, e.target.value, e.target.name),
                }}
              />

              <TextArea
                name="encoded_code"
                label="Enter Proposal Bytes Data"
                value={encoded_code}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleOnChange(item, e.target.value, e.target.name)
                }
                inputStatus={validityObject?.validBytes}
                disabled={!isProposalPeriod || locked || isFormDisabled}
              />

              <TextArea
                name="code_description"
                label="Enter Proposal Bytes Description"
                value={code_description ?? ''}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleOnChange(item, e.target.value, e.target.name)
                }
                onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => handleOnBlur(item, e)}
                inputStatus={validityObject?.validDescr}
                disabled={!isProposalPeriod || locked || isFormDisabled}
                textAreaMaxLimit={proposalDescriptionMaxLength}
              />

              <div className={`remove-byte ${!isProposalPeriod || locked ? 'disabled' : ''}`}>
                <Tooltip>
                  <Tooltip.Trigger>
                    <Button
                      kind={BUTTON_SIMPLE}
                      onClick={() => handleDeletePair(item.id)}
                      disabled={!isProposalPeriod || locked || isFormDisabled}
                    >
                      <Icon id="delete" />
                    </Button>
                  </Tooltip.Trigger>
                  <Tooltip.Content>Delete bytes pair</Tooltip.Content>
                </Tooltip>
              </div>
            </SubmitProposalBytesPair>
          )
        })}

        {proposalData.length >= 5 ? (
          <div className="bytes-restriction-banner">
            <Info
              text={
                'If you are adding a heavy load of bytes, such as bytes to create 20 farms, note that this can cause the operation size can be too large to execute successfully. We suggest to only create farms in batches of 5 per proposal'
              }
              type={INFO_WARNING}
            />
          </div>
        ) : null}

        <div className={`add-byte ${!isProposalPeriod || locked ? 'disabled' : ''}`}>
          <Button
            kind={BUTTON_SIMPLE_SMALL}
            disabled={!isProposalPeriod || locked || isFormDisabled}
            onClick={handleCreateNewByte}
          >
            <Icon id="plus" /> Add New Bytes
          </Button>
        </div>
      </SubmitProposalBytes>
    </>
  )
}
