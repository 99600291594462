import styled, { css } from 'styled-components'
import { Card, CardHover } from 'styles'
import { MavenTheme } from '../../styles/interfaces'

export const FinancialRequestsStyled = styled.div<{ theme: MavenTheme }>`
  display: flex;
  margin-top: 30px;

  .list-container {
    width: 50%;
    max-width: 540px;

    .list {
      margin-bottom: 37px;
    }
  }
`

export const FinancialRequestsRightContainer = styled(Card)<{ theme: MavenTheme }>`
  width: calc(50% - 25px);
  padding: 28px 30px;
  border-radius: 10px;
  height: min-content;
  margin-top: 0;
  flex-shrink: 0;
  margin-left: 30px;
  position: relative;
  padding-bottom: 86px;

  &::after {
    position: absolute;
    content: '';
    width: 44px;
    height: 3px;
    border-radius: 10px;
    bottom: 42px;
    left: 50%;
    background-color: ${({ theme }) => theme.mainHeadingText};
    transform: translateX(-50%);
  }

  .title-status {
    display: flex;
    justify-content: space-between;
  }

  .voting_ending {
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme }) => theme.primaryText};
  }

  .fr-voting {
    margin-bottom: 20px;

    > div {
      justify-content: space-between;
      column-gap: 15px;
      padding-top: 0px;

      button {
        width: 50%;
      }
    }
  }

  hr {
    border: none;
    height: 1px;
    background-color: ${({ theme }) => theme.divider};
    margin-bottom: 10px;
  }

  hr:last-of-type {
    margin: 20px 0;
  }

  .info_section_wrapper {
    display: flex;
    column-gap: 50px;
  }

  .info_section {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .list {
      display: flex;
      flex-direction: column;
      margin-top: 6px;
      width: 100%;

      .list_item {
        display: flex;
        width: 100%;
        justify-content: space-between;

        p {
          margin: 0;
        }
      }
    }
  }
`

export const InfoBlockTitle = styled.div<{ theme: MavenTheme }>`
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.mainHeadingText};
`

export const InfoBlockName = styled.div<{ theme: MavenTheme }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-top: 5px;
  color: ${({ theme }) => theme.regularText};
`

export const InfoBlockValue = styled(InfoBlockName)`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryText};
`

export const FRListItem = styled(CardHover)<{ selected: boolean; theme: MavenTheme }>`
  min-height: 57px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
  padding: 0 18px;
  border-radius: 10px;
  font-weight: 600;
  padding: 8px 28px;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      border-color: ${({ theme }) => theme.linksAndButtons};
      box-shadow: 0px 4px 4px ${({ theme }) => theme.cardHoverColor};
    `}
  .proposal-voted-mvn {
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme }) => theme.primaryText};
    margin-right: 10px;
    white-space: nowrap;
  }

  .id-and-title {
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-right: auto;

    > span {
      font-weight: 500;
      margin-right: 20px;
      color: ${({ theme }) => theme.regularText};
    }

    > h4 {
      font-weight: 500;
      padding-right: 8px;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`
