export const CREATE_VAULT_ACTION = 'createVault'
export const CHANGE_VAULT_NAME_ACTION = 'changeVaultName'
export const BORROW_VAULT_ASSET_ACTION = 'borrowVaultAssetAction'
export const REPAY_PART_OF_VAULT_ACTION = 'repayPartOfVaultAction'
export const REPAY_FULL_VAULT_ACTION = 'repayFullVaultAction'

// collateral consts
export const WITHDRAW_COLLATERAL_ACTION = 'withdrawCollateralAction'
export const DEPOSIT_COLLATERAL_ACTION = 'depositCollateralsAction'

// permission consts
export const CHANGE_BAKER_ACTION = 'changeBakerAction'
export const MANAGE_PERMISSIONS_ACTION = 'managePermissionsAction'
export const UPDATE_OPERATORS_ACTION = 'updateOperatorsAction'

// liquidation consts
export const MARK_FOR_LIQUIDATION_ACTION = 'markForLiquidateionAction'
export const LIQUIDATE_VAULT_ACTION = 'liquidateVaultAction'

export const MINIMUN_COLLATERAL_RATIO_PERSENT = 200
export const MAX_SHOWN_COLLATERAL_RATIO_PERSENT = 250
