import styled from 'styled-components'
import { MavenTheme } from 'styles/interfaces'

export const PageHeaderStyled = styled.div<{ $backgroundImageSrc: string }>`
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  padding: 0 0 0 40px;
  position: relative;
  margin-bottom: 30px;

  &::before {
    background-image: url(${({ $backgroundImageSrc }) => $backgroundImageSrc}),
      linear-gradient(
        90deg,
        #38237c 0%,
        #39277d 15%,
        #3f3383 30%,
        #48488c 45%,
        #556598 60%,
        #658aa9 76%,
        #79b8bc 92%,
        #86d4c9 100%
      );
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;
    border-radius: 15px;
    content: '';
    position: absolute;
    width: 100%;
    height: 150px;
    z-index: -1;
    bottom: 0;
    left: 0;
  }
`

export const PageHeaderTextArea = styled.div<{ theme: MavenTheme }>`
  max-width: 45%;
  width: max-content;
  overflow: visible;
  padding-top: 10px;

  &.loans {
    display: flex;
    column-gap: 10px;
  }

  h1 {
    color: ${({ theme }) => theme.pageHeaderColor};
    font-size: 25px;
    margin: 0;
    position: relative;

    &::after {
      background-color: #ff8486;
    }
  }

  .img-wrapper {
    width: 40px;
    height: 40px;
    position: absolute;
    right: -50px;
    top: -5px;
  }

  .asset-wrapper {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;

    .img-wrapper {
      position: relative;
      right: unset;
      top: unset;
    }

    svg {
      width: 40px;
      height: 40px;
      fill: ${({ theme }) => theme.pageHeaderColor};
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  p {
    color: ${({ theme }) => theme.pageHeaderColor};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    white-space: nowrap;
  }
`

export const PageHeaderForegroundImageContainer = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: self-end;
  overflow: visible;
`

export const PageHeaderForegroundImage = styled.img<{ $page: string; src?: string }>`
  position: absolute;
  right: ${({ $page }) => {
    switch ($page) {
      case 'governance':
      case 'council':
      case 'financial requests':
      case 'break glass':
      case 'emergency governance':
      case 'proposal submission':
        return '10px'
      case 'break glass council':
      default:
        return '0'
    }
  }};
  width: ${({ $page }) => {
    switch ($page) {
      case 'governance':
      case 'council':
      case 'financial requests':
      case 'dashboard':
      case 'staking':
        return 'fit-content'
      case 'vaults':
      case 'break glass council':
      default:
        return 'fit-content'
    }
  }};
  height: ${({ $page }) => {
    switch ($page) {
      case 'dashboard':
        return '172px'
      case 'break glass':
      case 'emergency governance':
        return '130px'
      case 'governance':
      case 'council':
      case 'financial requests':
      case 'proposal submission':
        return '158px'
      case 'satellite-governance':
        return '192px'
      case 'data-feeds':
        return '190px'
      case 'farms':
      case 'break glass council':
      case 'loansEarn':
      case 'loansBorrow':
        return '150px'
      default:
        return '172px'
    }
  }};
  bottom: ${({ $page }) => {
    switch ($page) {
      case 'satellites':
        return '-20px'
      case 'lending':
      case 'loansDashboard':
        return '-13px'
      case 'governance':
      case 'council':
      case 'financial requests':
      case 'proposal submission':
        return '7px'
      case 'emergency governance':
        return '-2px'
      case 'break glass council':
      case 'loansEarn':
      case 'loansBorrow':
        return '-10px'
      default:
        return '0'
    }
  }};
  /*
  TODO: Uncomment when starting to work on animation
  animation: {({ page }) => {
    switch (page) {
      case 'staking':
      case 'vaults':
        return {shakes} 3s linear infinite  //re-add the $ before {shakes} and outside back tiks 
      case 'warning':
        return ({ theme }) => theme.warningColor
      case 'error':
        return ({ theme }) => theme.downColor
      default:
        return null
    }
  }};
   */
`
