// Loaders contants
export const ROCKET_LOADER = 'rocket'
export const WERT_IO_LOADER = 'wert-loader'

// User tokens symbols
export const MVN_TOKEN_SYMBOL = 'MVN'
export const MVRK_TOKEN_SYMBOL = 'MVRK'
export const SMVN_TOKEN_ADDRESS = 'sMVN'
export const MVRK_TOKEN_ADDRESS = 'mv2ZZZZZZZZZZZZZZZZZZZZZZZZZZZDXMF2d'

// User token types
export const USER_TOKEN_TYPE_COLLATERAL = 'collateralToken'
export const USER_TOKEN_TYPE_WHITELIST = 'whitelistToken'
export const USER_TOKEN_TYPE_MTOKEN = 'mToken'
export const USER_TOKEN_TYPE_DEFAULT = 'defaultDDtoken'

// Decimals constants
export const PRECISION_NUMBER = 1_000_000_000 // (10^9) MVN decimals
export const MU_NUMBER = 1_000_000 // (10^6) MVRK decimals
export const MVN_DECIMALS = 9
export const MVRK_DECIMALS = 6

// Decimals parsing constants
export const DECIMALS_TO_SHOW = 2
export const ACCURATE_DECIMALS_TO_SHOW = 9

export const BLOCKS_PER_MINUTE = 7.5
export const SECONDS_PER_BLOCK = 30
export const FIXED_POINT_ACCURACY = 10 ** 27
export const SECONDS_PER_YEAR = 60 * 60 * 24 * 365

export const DEFAULT_MIN_COLLATERAL_AMOUNT = 0.0001

export const TEMP_MAX_ORACLE_DATA_PUSH_SECONDS = 900
