import styled, { css } from 'styled-components'
import { MavenTheme } from 'styles/interfaces'

const HOVERABLE_TABLE_ROW_STYLES = css`
  &.add-hover {
    &:hover {
      > td {
        color: ${({ theme }) => theme.linksAndButtons};
        * {
          color: ${({ theme }) => theme.linksAndButtons};

          svg {
            stroke: ${({ theme }) => theme.linksAndButtons};
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 0.5px solid ${({ theme }) => theme.linksAndButtons};
      }
    }
  }
`

const EDITABLE_TABLE_ROW_STYLES = css`
  &.editable-row {
    height: 42px;
    position: relative;

    td {
      color: ${({ theme }) => theme.primaryText};
      vertical-align: middle;
      text-align: center;

      > div {
        margin: 0 auto;
      }
    }

    &:hover {
      > div:not(.button-wrap),
      input {
        background-color: transparent;
        border-color: transparent;
      }

      .remove {
        opacity: 1;
      }
    }
  }
`

const PROPOSAL_DETAILS_PAYMENTS_ROW_STYLES = css`
  &.proposal-details-payments {
    td {
      font-size: 14px;
      font-weight: 400;

      > div {
        margin: 0 auto;
        width: fit-content;
      }
    }
  }
`

export const TableRow = styled.tr<{ theme: MavenTheme; $borderColor?: string; $rowHeight?: number }>`
  height: ${({ $rowHeight = 55 }) => `${$rowHeight}px`};
  transition: border-bottom 0.4s;

  &:not(:last-child) {
    border-bottom: 0.5px solid ${({ theme, $borderColor = 'divider' }) => theme[$borderColor]};
  }

  &.plug-row {
    position: relative;

    .plug-row-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
      font-size: 14px;
      color: ${({ theme }) => theme.mainHeadingText};
    }
  }

  ${HOVERABLE_TABLE_ROW_STYLES}

  ${EDITABLE_TABLE_ROW_STYLES}

  ${PROPOSAL_DETAILS_PAYMENTS_ROW_STYLES}
`
