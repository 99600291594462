export const COLLATERAL_AWARE_BORROWING_ADJUST_YOUR_AMOUNT =
  'The amount you wish to borrow would under-collateralize your vault. Please enter a different amount to borrow so your vault will not be under-collateralized when you borrow.'
export const SELECT_THE_AMOUNT_YOU_WOULD_LIKE_TO_BORROW =
  'Select the amount you would like to borrow. You cannot borrow more than your borrow capacity.'
export const CONTRACT_COMPLIANT_REPAYMENT_ADJUST_AND_REFUND =
  'Your outstanding debt is less than the minimum repayment amount set by the smart contracts. We will have you repay the minimum repayment amount and the amount you are overpaying will automatically be refunded by the smart contract.'
export const OVER_REPAYING_ROUNDED_WARNING_TEXT =
  'We round up to cover any interest accrued since last interacted with the vault. Any remaining amount after repaying will automatically be refunded to your wallet.'
export const OVER_REPAYING_WARNING_TEXT =
  'Any remaining amount after repaying will automatically be refunded to your wallet.'
export const PARTIAL_LOAN_REPAYMENT = 'You are no longer fully repaying there loan.'
