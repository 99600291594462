import styled, { css } from 'styled-components'
import { DEFAULT_Z_INDEX_FOR_OVERLAP } from 'styles/constants'
import { MavenTheme } from 'styles/interfaces'

export const TableScrollable = styled.div<{ theme: MavenTheme; bodyHeight: number }>`
  overflow-y: auto;
  padding-right: 8px;
  overscroll-behavior: contain;
  ${({ bodyHeight }) => css`
    height: ${bodyHeight}px;
  `}

  thead {
    position: sticky;
    background-color: ${({ theme }) => theme.cards};
    top: 0;
    z-index: ${DEFAULT_Z_INDEX_FOR_OVERLAP};
  }

  &.treasury-table {
    margin-top: 30px;
  }

  &.dashboard-loans-table {
    padding-right: 30px;
  }
`

export const Table = styled.table<{ theme: MavenTheme }>`
  width: 100%;

  border-collapse: collapse;

  &.borrowing-table {
    position: relative;
  }

  &.treasury-table {
    margin-top: 30px;
  }

  &.editable-table {
    margin-top: 15px;
    border: 1px solid ${({ theme }) => theme.strokeColor};
    position: relative;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;

    &.with-header {
      thead {
        th:first-child {
          border-top: unset;
          border-left: unset;
          border-top-left-radius: 10px;
        }

        th:last-child {
          border-top: unset;
          border-right: unset;
          border-top-right-radius: 10px;
        }
      }

      tr:not(.plug-row) {
        td {
          border-right: 1px solid ${({ theme }) => theme.strokeColor};
        }
      }

      tr {
        td {
          border: unset;
          border-top: 1px solid ${({ theme }) => theme.strokeColor};
        }

        td:last-of-type {
          border: unset;
          border-right: unset;
          border-top: 1px solid ${({ theme }) => theme.strokeColor};
        }
      }
    }

    &.one-column {
      td {
        border-top: 1px solid ${({ theme }) => theme.strokeColor};
      }

      tr:first-child {
        td {
          border-right: unset;
          border-top: unset;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom: unset;
        border-left: unset;
        border-bottom-left-radius: 10px;
      }

      td:last-child {
        border-bottom: unset;
        border-right: unset;
        border-bottom-right-radius: 10px;
      }
    }

    td {
      input {
        background: transparent;
      }
    }
  }

  &.transaction-history-table {
    margin-top: 30px;
  }

  &.fix-mistaken-transfer-table {
    margin: 0;

    .remove {
      right: -18px;
    }

    .add {
      margin-left: 5px;
    }
  }
`

export const TableBody = styled.tbody<{ theme: MavenTheme }>`
  &.treasury {
    th,
    td {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &.dashboard-loans {
    svg {
      width: 60px;
      height: 60px;
    }

    th,
    td {
      font-size: 14px;
      font-weight: 600;
    }

    td:first-child {
      font-size: 18px;
    }
  }

  &.transaction-history {
    * {
      font-size: 14px;
    }

    .descr {
      font-size: 16px;
    }
  }
`
