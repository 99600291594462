import styled from 'styled-components'
import { zoomIn, slideDown } from 'styles/animations'
import { MavenTheme } from '../../../styles/interfaces'
import { INPUT_STATUS_ERROR, INPUT_STATUS_SUCCESS } from '../Input/Input.constants'

export const IPFSUploaderStyled = styled.div<{ theme: MavenTheme }>`
  margin-bottom: 5px;
  margin-top: 19px;

  label {
    position: relative;
    bottom: 5px;
    left: 10px;

    color: ${({ theme }) => theme.mainHeadingText};
    font-weight: 700;
  }
`

export const UploaderFileSelector = styled.div<{ $validation: string; theme: MavenTheme }>`
  cursor: pointer;
  height: 107px;
  width: 100%;
  background-color: transparent;
  background-position: center;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ $validation, theme }) =>
    $validation === INPUT_STATUS_SUCCESS
      ? `
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgb(39, 174, 96)' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    `
      : $validation === INPUT_STATUS_ERROR
      ? `
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgb(255, 67, 67)' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    `
      : `background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='${theme.ipfsBorderColor}' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`}

  &.disabled {
    cursor: default;
  }

  &:hover:not(.disabled) {
    background-image: ${({ theme }) =>
      `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='${theme.ipfsHoverBorderColor}' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`};

    figcaption {
      color: ${({ theme }) => theme.linksAndButtons};
    }

    .upload-icon {
      fill: ${({ theme }) => theme.linksAndButtons};
    }
  }

  .delete-icon {
    position: absolute;
    fill: ${({ theme }) => theme.mainHeadingText};
    width: 16px;
    height: 16px;
    display: block;
    top: 16px;
    right: 16px;
  }

  > div {
    width: 100%;
    height: 100%;
    position: relative;

    > input {
      all: unset;
      display: inline-block;
      border-radius: 10px;
      outline: none;
      width: 100%;
      height: 100%;
      appearance: initial;
      opacity: 0;
      position: relative;
      -webkit-appearance: none;
    }
  }

  .loading-icon {
    width: 30px;
    height: 30px;
  }
`

export const UploadIconContainer = styled.div<{
  $uploadIsFailed: boolean
  theme: MavenTheme
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* width: 50px;
  height: 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  .upload-figure {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.mainHeadingText};
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    small {
      font-weight: 400;
      padding-top: 4px;
      font-size: 10px;
      line-height: 10px;
      color: ${({ theme }) => theme.mainHeadingText};
    }

    figcaption {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }

    .tip {
      color: ${({ $uploadIsFailed, theme }) => $uploadIsFailed && theme.downColor};
    }
  }

  .icon-wrap {
    height: 39px;
  }

  .upload-icon {
    fill: ${({ theme }) => theme.mainHeadingText};
    width: 24px;
    height: 24px;
  }

  .pencil-wrap {
    width: 16px;
    height: 16px;
    background-color: ${({ theme }) => theme.mainHeadingText};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0px;

    svg {
      width: 10px;
      height: 10px;
      stroke: ${({ theme }) => theme.cards};
    }
  }
`

export const IPFSUploaderStatus = styled.div`
  display: block;
  position: absolute;
  top: 25px;
  right: 10px;
  z-index: 1;
  margin-top: -10px;
  line-height: 13px;
  text-align: center;
  visibility: visible;
  pointer-events: none;
  will-change: transform, opacity;

  &.error {
    background-image: url('/icons/input-error.svg');
    animation: ${zoomIn} 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    height: 15px;
    width: 15px;
  }

  &.success {
    background-image: url('/icons/input-success.svg');
    animation: ${zoomIn} 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    height: 12px;
    width: 17px;
  }
`

export const IpfsUploadedImageContainer = styled.figure`
  margin: 0;
  position: relative;
  width: 50px;
  height: 50px;

  .img-wrapper,
  svg {
    height: 50px;
    width: 50px;
    border-radius: 50%;

    fill: ${({ theme }) => theme.pageHeaderColor};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`

export const IPFSUploaderErrorMessage = styled.div<{ theme: MavenTheme }>`
  color: ${({ theme }) => theme.downColor};
  line-height: 24px;
  will-change: transform, opacity;
  animation: ${slideDown} 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-letter {
    text-transform: uppercase;
  }
`
