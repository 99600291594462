export const mavrykLinks = [
  {
    link: 'https://docs.google.com/document/d/1jW-XtRPv3TsCV2meV2ajgkQ6dI0iEwuz9xgZwnyMliw/edit',
    title: 'Privacy Policy',
  },
  {
    link: 'https://docs.google.com/document/d/1R0LA7CmVQjH7vr-FvWOy96LRxJ_XU3HXLXnqNZjZlJQ/edit',
    title: 'Terms of Use',
  },
]
