import styled from 'styled-components'
import { MavenTheme } from 'styles/interfaces'

export const LoansModalBase = styled.div<{ theme: MavenTheme }>`
  h2 {
    font-size: 22px;
  }

  .useMax-btn {
    button {
      font-size: 14px;
    }
  }

  .modalDescr {
    margin-top: 7px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${({ theme }) => theme.subHeadingText};
    margin-bottom: 30px;

    p {
      margin: 0;
    }
  }

  .block-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${({ theme }) => theme.mainHeadingText};
    padding-left: 7px;
    padding-bottom: 3px;
  }

  .confirmation-btn {
    margin: 55px auto 0;
    width: 300px;
  }

  .manage-btn {
    display: flex;
    width: 250px;
    justify-content: center;
    margin: 30px auto 0 auto;
  }

  .repayFull-banner {
    margin-top: 30px;
  }

  .buttons-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-top: 60px;
  }

  hr {
    background: ${({ theme }) => theme.divider};
    margin: 40px 0;
    height: 0.5px;
    border: none;
  }

  .loans-confirmation-info {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 0 20px;

    > div {
      margin: 0;
    }

    hr {
      margin: 0;
    }
  }

  .confirmation-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div:last-of-type {
      display: grid;
      justify-items: flex-end;
    }
  }

  .align-tree-item-right {
    display: grid;
    justify-items: flex-end;
  }

  .screen-bottom-stats {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  .collateral-list-wrapper-for-overflow {
    height: 280px; /*add additional 20px cuz of mb space, cuz list is positioned as absolute*/
    position: relative;
  }

  .collateral-list {
    overscroll-behavior: contain;
    display: flex;
    flex-direction: column;
    min-height: 77px;
    row-gap: 50px;
    margin-bottom: 20px;
  }

  .collateral-list-overflow {
    padding-right: 22px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 27px); /*icon + scrollbar*/
    padding-top: 18px; /*to show use-max block on the first collateral input*/
    max-height: 260px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .xtz-baker {
    margin: 30px 0 -20px 0;
  }

  .collateral-block-wrapper:last-of-type {
    & > div:last-of-type {
      margin-bottom: 0;
    }
  }

  .collateral-block-wrapper:last-of-type {
    & > div:first-of-type {
      margin-bottom: 20px;
    }
  }

  .creating-vault-loader-wrapper {
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: -30px;
    display: flex;
    justify-content: center;
    column-gap: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    color: ${({ theme }) => theme.regularText};
  }

  .lending-stats {
    display: flex;
    justify-content: space-between;

    .name {
      .tooltip {
        svg {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .add-collateral-inline {
    margin-top: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .confirm-create-vault {
    display: grid;
    grid-template-columns: minmax(33%, 1.2fr) minmax(33%, 1.2fr) 1fr;
    margin-top: 20px;
    column-gap: 30px;
  }
`

export const VaultModalOverview = styled.div<{ theme: MavenTheme }>`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.strokeCards};
  padding: 14px 15px 24px 20px;
  border-radius: 10px;

  .collateral-diagram {
    .diagram {
      max-width: 140px;
    }

    .copyIcon {
      font-weight: 600;
    }
  }
`
