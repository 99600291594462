import { gql as apolloGql, OperationVariables, TypedDocumentNode } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { gql } from 'utils/__generated__'
import { GetDevLoansTransactionsHistoryQuery, GetLoansTransactionsHistoryQuery } from 'utils/__generated__/graphql'

// Cals 24h diffs
export const LEND_BORROW_24H_DIFF = gql(`
query getLending24hDiff($currentTimestamp: timestamptz) {
  lending_controller: lending_controller {
    history_data(where: {type: {_in: ["0", "1", "2", "3"]}, timestamp: {_gte: $currentTimestamp}}, order_by: {timestamp: asc}) {
      type
      amount
      timestamp
      loan_token {
        token {
          token_address
        }
      }
    }
  
    loan_tokens {
      total_borrowed
      token_pool_total
      token {
        token_address
      }
    }
  }
}
`)

// Loans history data
export const GET_LOANS_HISTORY_DATA = gql(`
query getLoansHistoryData {
  lending_controller: lending_controller {
    history_data(where: {type: {_in: ["0", "1", "2", "3", "4", "5", "6", "7"]}}, order_by: {timestamp: asc}) {
      type
      amount
      timestamp
      loan_token {
        loan_token_name
        token {
          token_address
        }
      }
      collateral_token {
        token {
          token_address
        }
      }
    }
  }
}
`)

/**
 *
 * @param userAddress - get transctions of specific user
 * @param vaultAddress - get transactions on specific vault only
 * @param typeFilter - get specific operation types only
 *
 * TODO: check refetch logic
 */
export function getLoansTransactionsHistory({
  userAddress,
  vaultAddress,
  typeFilter,
}: {
  userAddress?: string
  vaultAddress?: string
  typeFilter?: Array<number>
}): DocumentNode | TypedDocumentNode<GetLoansTransactionsHistoryQuery, OperationVariables> {
  const filterUserCondition = userAddress ? `sender: {address: {_eq: $userAddress}}` : `sender: {address: {_neq: ""}}`
  const filterVaultCondition = vaultAddress ? `vault: { vault: {address: {_eq: $vaultAddress}}}` : ''
  const filterTypeCondition = typeFilter
    ? `type: {_in: $typeFilter}`
    : `type: {_in: ["0", "1", "2", "3", "4", "5", "6", "7"]}`

  return apolloGql(`
    query getLoansTransactionsHistory($marketTokenAddress: String, $userAddress: String = "", $vaultAddress: String = "", $typeFilter: [smallint] = [], $offset: Int = 0, $limit: Int = 8) {
      lending_controller: lending_controller {
        history_data(where: {${filterTypeCondition}, loan_token: {token: {token_address: {_eq: $marketTokenAddress}}}, ${filterUserCondition}, ${filterVaultCondition}}, order_by: {timestamp: desc}, offset: $offset, limit: $limit) {
          type
          amount
          timestamp
          loan_token {
            loan_token_name
            token {
              token_address
            }
          }
          collateral_token {
            token {
              token_address
              mvn_tokens {
                address
              }
            }
          }
          operation_hash
          sender {
            address
          }
          vault {
            vault {
              address
            }
          }
        }

        historyItemsAmount: history_data_aggregate(where: {${filterTypeCondition}, loan_token: {token: {token_address: {_eq: $marketTokenAddress}}}, ${filterUserCondition}, ${filterVaultCondition}}) {
          aggregate {
            count
          }
        }
      }
    }
`)
}

export function getDevLoansTransactionsHistory({
  userAddress,
  vaultAddress,
  typeFilter,
}: {
  userAddress?: string
  vaultAddress?: string
  typeFilter?: Array<number>
}): DocumentNode | TypedDocumentNode<GetDevLoansTransactionsHistoryQuery, OperationVariables> {
  const filterUserCondition = userAddress ? `sender: {address: {_eq: $userAddress}}` : `sender: {address: {_neq: ""}}`
  const filterVaultCondition = vaultAddress ? `vault: { vault: {address: {_eq: $vaultAddress}}}` : ''
  const filterTypeCondition = typeFilter
    ? `type: {_in: $typeFilter}`
    : `type: {_in: ["0", "1", "2", "3", "4", "5", "6", "7"]}`

  return apolloGql(`
    query getDevLoansTransactionsHistory($marketTokenAddress: String, $userAddress: String = "", $vaultAddress: String = "", $typeFilter: [smallint] = [], $offset: Int = 0, $limit: Int = 8) {
      lending_controller: lending_controller {
        history_data(where: {${filterTypeCondition}, loan_token: {token: {token_address: {_eq: $marketTokenAddress}}}, ${filterUserCondition}, ${filterVaultCondition}}, order_by: {timestamp: desc}, offset: $offset, limit: $limit) {
          type
          amount
          timestamp
          loan_token {
            loan_token_name
            token {
              token_address
            }
          }
          collateral_token {
            token {
              token_address
              mvn_tokens {
                address
              }
            }
          }
          operation_hash
          sender {
            address
          }
          vault {
            vault {
              address
            }
          }
        }

        historyItemsAmount: history_data_aggregate(where: {${filterTypeCondition}, loan_token: {token: {token_address: {_eq: $marketTokenAddress}}}, ${filterUserCondition}, ${filterVaultCondition}}) {
          aggregate {
            count
          }
        }
      }
    }
`)
}
