import styled from 'styled-components'
import { MavenTheme } from './interfaces'

export const Page = styled.div<{ theme: MavenTheme }>`
  margin: auto;
  padding: 40px;
  width: 100%;
  position: relative;
  height: 100%;
  min-height: 100vh;
  width: 1170px;
  max-width: 1170px;
  padding-top: 112px;

  a.disabled-link {
    pointer-events: none;
  }
`

export const GridPage = styled.div`
  margin: 30px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
  max-width: 1270px;

  @media (max-width: 1900px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Message = styled.div`
  text-align: center;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50vh;
`

export const Card = styled.div<{ theme: MavenTheme }>`
  margin-top: 30px;
  background-color: ${({ theme }) => theme.cards};
  border-radius: 10px;
  padding: 25px 35px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.subHeadingText};
  border: 1px solid ${({ theme }) => theme.strokeCards};
`

export const CardHover = styled(Card)`
  &:hover {
    border-color: ${({ theme }) => theme.linksAndButtons};
    box-shadow: 0px 4px 4px ${({ theme }) => theme.cardHoverColor};
  }
`

export const CardHeader = styled.h2<{ theme: MavenTheme }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
`

export const PageContent = styled.section`
  display: grid;
  grid-template-columns: 750px 310px;
  grid-gap: 30px;
  align-items: baseline;
  align-items: start;
`
