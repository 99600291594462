const Backdrop = ({
  className = '',
  color,
  opacity = 0.3,
}: {
  className?: string
  color: string
  opacity?: number
}) => (
  <svg width={118} height={86} viewBox="0 0 118 86" fill="none" className={className}>
    <path
      opacity={opacity}
      d="M112.009 84.2905C116.421 75.306 118.454 65.3398 117.913 55.345C117.372 45.3503 114.277 35.6615 108.921 27.2052C103.566 18.749 96.1305 11.8083 87.3262 7.04699C78.5218 2.28567 68.6431 -0.136975 58.6349 0.0107856C48.6266 0.158545 38.8237 2.87177 30.1637 7.89093C21.5038 12.9101 14.2765 20.0673 9.1732 28.6779C4.0699 37.2885 1.26128 47.0646 1.01601 57.0709C0.770732 67.0772 3.097 76.9791 7.7723 85.8294L59.4984 58.5044L112.009 84.2905Z"
      fill={color}
    />
  </svg>
)

export default Backdrop
